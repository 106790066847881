// Angular
import { Injectable } from '@angular/core';
// RxJS
import { of } from 'rxjs';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { HeadquarterStoresService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
  HeadquarterStoreActionTypes,
  HeadquarterStoresPageRequested,
  HeadquarterStoresPageLoaded,
  ManyHeadquarterStoresDeleted,
  OneHeadquarterStoreDeleted,
  HeadquarterStoresPageToggleLoading,
  HeadquarterStoreUpdated,
  HeadquarterStoreCreated,
  HeadquarterStoreOnServerCreated,
} from '../_actions/headquarter-store.actions';

@Injectable()
export class HeadquarterStoreEffects {
  // showLoadingDistpatcher = new ProcutStoresPageToggleLoading({ isLoading: true });
  hideLoadingDistpatcher = new HeadquarterStoresPageToggleLoading({
    isLoading: false,
  });

  @Effect()
  loadHeadquarterStoresPage$ = this.actions$.pipe(
    ofType<HeadquarterStoresPageRequested>(
      HeadquarterStoreActionTypes.HeadquarterStoresPageRequested,
    ),
    mergeMap(({ payload }) =>
      this.headquarterStoresService.findHeadquarterStores(
        payload.page,
        payload.headquarterId,
      ),
    ),
    map((result: QueryResultsModel) => {
      return new HeadquarterStoresPageLoaded({
        headquarterStores: result.items,
        totalCount: result.totalCount,
      });
    }),
  );

  @Effect()
  deleteHeadquarterStore$ = this.actions$.pipe(
    ofType<OneHeadquarterStoreDeleted>(
      HeadquarterStoreActionTypes.OneHeadquarterStoreDeleted,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new HeadquarterStoresPageToggleLoading({ isLoading: true }),
      );
      return this.headquarterStoresService.deleteHeadquarterStore(
        payload.headquarterId,
        payload.storeId,
      );
    }),
    map(() => {
      return this.hideLoadingDistpatcher;
    }),
  );

  @Effect()
  deleteHeadquarterStores$ = this.actions$.pipe(
    ofType<ManyHeadquarterStoresDeleted>(
      HeadquarterStoreActionTypes.ManyHeadquarterStoresDeleted,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new HeadquarterStoresPageToggleLoading({ isLoading: true }),
      );
      return this.headquarterStoresService.deleteHeadquarterStores(payload.ids);
    }),
    map(() => {
      return this.hideLoadingDistpatcher;
    }),
  );

  @Effect()
  updateHeadquarterStore$ = this.actions$.pipe(
    ofType<HeadquarterStoreUpdated>(
      HeadquarterStoreActionTypes.HeadquarterStoreUpdated,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new HeadquarterStoresPageToggleLoading({ isLoading: true }),
      );
      return this.headquarterStoresService.updateHeadquarterSpec(
        payload.headquarterStore,
      );
    }),
    map(() => {
      return this.hideLoadingDistpatcher;
    }),
  );

  @Effect()
  createHeadquarterStore$ = this.actions$.pipe(
    ofType<HeadquarterStoreOnServerCreated>(
      HeadquarterStoreActionTypes.HeadquarterStoreOnServerCreated,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new HeadquarterStoresPageToggleLoading({ isLoading: true }),
      );
      return this.headquarterStoresService
        .createHeadquarterStore(payload.store)
        .pipe(
          tap((res) => {
            this.store.dispatch(
              new HeadquarterStoreCreated({ headquarterStore: res }),
            );
          }),
        );
    }),
    map(() => {
      return this.hideLoadingDistpatcher;
    }),
  );

  constructor(
    private actions$: Actions,
    private headquarterStoresService: HeadquarterStoresService,
    private store: Store<AppState>,
  ) {}
}
