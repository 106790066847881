export function objectToStr(obj: object): string {
  const p = [];
  for (let k in obj) {
    if (obj[k]) {
        if (typeof obj[k] === 'object' && !Array.isArray(obj[k]) && !(obj[k] instanceof Date)) {
            p.push(`${k}=${encodeURIComponent(JSON.stringify(obj[k]))}`);
        } else if((obj[k] instanceof Date)) {
            p.push(`${k}=${obj[k].toISOString()}`)
        } else {
            p.push(`${k}=${obj[k]}`);
        }
    }
  }
  return p.join('&');
}
