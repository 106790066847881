<div
  class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
  id="kt_login"
>
  <div
    class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile"
    [ngClass]="{
      'background-info-video': false
    }"
  >
    <!--begin::Aside-->
    <div
      class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
      style="background-image: url(./assets/media//bg/bg-4.jpg)"
    >
      <div class="kt-grid__item">
        <a href="javascript:;" class="kt-login__logo"> </a>
      </div>
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
        <div class="kt-grid__item kt-grid__item--middle">
          <h3 class="kt-login__title">Verticula</h3>
        </div>
      </div>
      <div class="kt-grid__item">
        <div class="kt-login__info">
          <div class="kt-login__copyright"></div>
          <div class="kt-login__menu">
            <a href="javascript:;" class="kt-link">{{
              'AUTH.GENERAL.PRIVACY' | translate
            }}</a>
            <a href="javascript:;" class="kt-link">{{
              'AUTH.GENERAL.LEGAL' | translate
            }}</a>
            <a href="javascript:;" class="kt-link">{{
              'AUTH.GENERAL.CONTACT' | translate
            }}</a>
          </div>
        </div>
      </div>
    </div>
    <!--begin::Aside-->

    <!--begin::Content-->
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper"
      [ngClass]="{
        'padding-info-video': false
      }"
    >
      <router-outlet></router-outlet>
    </div>
    <!--end::Content-->
  </div>
</div>
