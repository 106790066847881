export const locale = {
  lang: 'se',
  data: {
    COMMON: {
      PRICE: 'Pris',
      MONTH: 'månad',
      NO: 'Nej',
      YES: 'Ja',
      HEADQUARTER: 'Huvudkontor'
    },
    INFO: {
      SEARCH: 'Sök',
      SEARCHING: 'Söker',
    },
    TRANSLATOR: {
      SELECT: 'Välj språk',
    },
    MENU: {
      NEW: 'Ny',
      ACTIONS: 'Actions',
      CREATE_POST: 'Skapa ny post',
      PAGES: 'Sidor',
      FEATURES: 'Funktioner',
      APPS: 'Appar',
      DASHBOARD: 'Dashboard',
      CATALOG: 'Katalog',
      ORDERS: 'Orders',
      ORDERS_VIEW: 'View',
      ADMINISTRATION: 'ADMINISTRATION',
    },
    AUTH: {
      GENERAL: {
        OR: 'eller',
        SUBMIT_BUTTON: 'Skicka',
        NO_ACCOUNT: 'Inget konto?',
        SIGNUP_BUTTON: 'Registrera',
        FORGOT_BUTTON: 'Glömt lösenord',
        BACK_BUTTON: 'Tillbaka',
        PRIVACY: 'Integritet',
        LEGAL: 'Rättslig',
        CONTACT: 'Kontakt',
      },
      LOGIN: {
        TITLE: 'Login konto',
        BUTTON: 'Logga in',
      },
        LOGOUT: {
            BUTTON: 'Logga ut',
        },
      FORGOT: {
        TITLE: 'ange din  eposadress',
        DESC: 'Ange din epostadress för att återställa ditt lösenord',
        SUCCESS: 'Ditt lösenord har blivit återställt.',
        SENT: 'Du har fått en epost med återställningsinstruktioner',
      },
      REGISTER: {
        TITLE: 'Registrera',
        DESC: 'Ange uppgifter för att skapa konto',
        SUCCESS: 'Ditt konto har blivit registrerat.',
      },
      INPUT: {
        EMAIL: 'Epost',
        FULLNAME: 'Fullständigt namn',
        PASSWORD: 'Lösenord',
        CONFIRM_PASSWORD: 'Bekräfta lösenord',
        USERNAME: 'Användarnamn',
      },
      VALIDATION: {
        INVALID: '{{name}} är inte giltigt',
        REQUIRED: '{{name}} krävs',
        MIN_LENGTH: '{{name}} minsta längd är {{min}}',
        AGREEMENT_REQUIRED: 'Acceptera villkor är nödvändigt.',
        NOT_FOUND: 'begärt {{name}} hittades ej',
        INVALID_LOGIN: 'inloggningsuppgifterna är felaktiga',
        REQUIRED_FIELD: 'Obligatoriskt fält',
        MIN_LENGTH_FIELD: 'Minsta längd fält:',
        MAX_LENGTH_FIELD: 'Maximal längd fält:',
        INVALID_FIELD: 'Ogiltigt fält',
      },
      SERVER_ERRORS: {
        incorrect_password_or_email: 'Fel lösenord eller epost',
        ip_not_allowed: 'Din IP address är inte tillåten.',
        wrong_password: 'Fel lösenord',
        missing_user: 'Saknar användare',
        too_many_requests: 'För många förfrågningar',
        invalid_credentials: 'Ogiltiga uppgifter',
      },
    },
    USER: {
      HELLO: 'Hej',
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Valt registerantal: ',
        ALL: 'Alla',
        SUSPENDED: 'Suspenderad',
        ACTIVE: 'Aktiv',
        FILTER: 'Filtrera',
        BY_STATUS: 'på status',
        BY_TYPE: 'på typ',
        BUSINESS: 'Företag',
        INDIVIDUAL: 'Enskild',
        SEARCH: 'Sök',
        IN_ALL_FIELDS: 'i samtliga fält',
      },
      ECOMMERCE: 'ehandel',
      CUSTOMERS: {
        CUSTOMERS: 'Kunder',
        CUSTOMERS_LIST: 'Kundlista',
        NEW_CUSTOMER: 'Ny kund',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Radera kund',
          DESCRIPTION: 'Är du säker på att du vill radera kunden permanent?',
          WAIT_DESCRIPTION: 'Raderar kund...',
          MESSAGE: 'Kunden har raderats',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Radera kunder',
          DESCRIPTION: 'Är du säker på att du vill radera kunderna permanent?',
          WAIT_DESCRIPTION: 'Kunderna raderats...',
          MESSAGE: 'Valda kunder har raderats',
        },
        UPDATE_STATUS: {
          TITLE: 'Status har uppdaterats för valda kunder',
          MESSAGE: 'Valda kunders status har uppdaterats',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Kunden har uppdaterats',
          ADD_MESSAGE: 'Kunden har skapats',
        },
      },
    },
    PRICING_INPUT: {
      TITLE: 'Prissättning',
      PURCHASE_PRICE: 'Inköpspris',
      SALES_PRICE: 'Försäljningspris',
      ALL_VALUE_FILTER: 'Alla',
      CONTRACT_NAME: 'Abonnemang',
      TABLE: {
        PROVIDER_NAME: 'Operatör',
        NAME: 'Namn',
        DISCOUNT: 'Rabatt',
        SUBVENTION: 'Subvention',
        IMEI_FIRST: 'IMEI 1',
        IMEI_SECOND: 'IMEI 2',
        IMEI_THIRD: 'IMEI 3',
        MANUAL_PRICE_PER_MONTH: 'Manuell månadskostnad',
        MANUAL_PRICE: 'Manuellt pris',
        TO_PAY: 'Att betala',
        COST: 'Kostnad',
        PRIORITY: 'Prioritet',
        MORE: 'Mer',
        MODIFY: 'Redigera',
        SAVE: 'Spara',
        CANCEL: 'Ångra',
        TOTAL_COMMISSION: 'Ersättning',
        PURCHASE_PRICE: 'Inköpspris',
        TOTAL: 'Totalt',
      },
      ERRORS: {
        ALLOWED_INPUT: 'Tillåten',
      },
    },
    CONTRACT_INPUT: {
      TITLE: 'Abonnemang masterdata',
      ARTICLE_FILTER: 'Artikelfilter',
      PROVIDER_NAME: 'Operatör',
      MONTH_LENGTH: 'Bindningstid',
      ADD_NEW: 'Lägg till ny',
      TABLE: {
        MANUFACTURER: 'Tillverkare',
        ARTICLE_NAME: 'Artiklelnamn',
        MONTH: 'Månad',
        DISCOUNT: 'Rabatt',
        MONTHLY_COST: 'Månadskostnad',
        DATA: 'data',
        FEE_IN_STORE: 'Avgift i butik',
        FEE_AMOUNT: 'Avgift',
        FEE_ARTICLE: 'Avgift artikel',
        STAFF_BONUS: 'Personal bonus',
        FOCUSED: 'Fokuserad',
        MODIFY: 'Redigera',
        EDIT: 'Ändra',
      },
      EDIT_PAGE: {
        TITLE: 'Abonnemang masterdata',
        SEARCH_INFO: {
          TITLE: 'sök info',
          PROVIDER_NAME: 'Operatör',
          CONTRACT_TYPE: 'Abonnemangstyp',
          PRODUCT_SEARCH: 'Artikelsök',
          UNDO: 'Ångra',
          SAVE: 'Spara',
        },
        PRODUCT_INFO: {
          TITLE: 'Produktinformation',
          PRODUCT_GROUP: 'Produktgrupp',
          MANUFACTURER: 'Tillverkare',
          ARTICLE_NUMBER: 'Artikelnummer',
          ARTICLE_NAME: 'Artikelnamn',
          CONTRACT_LENGTH: 'Bindningstid',
          DISCOUNT: 'Rabatt',
          MONTHLY_COST: 'Månadskostnad',
          DATA_INCLUDED: 'Inkluderad datamängd',
          CONTRACT_TYPE: 'Abonnemangstyp',
          EXTRA_SIM_FLAG: 'Extra sim flagg',
          ONLINE_VISIBLE: 'Aktiv online',
        },
        CALCULATION_INFO: {
          TITLE: 'Beräkning',
          TOTAL_COMMISSION: 'Total ersättning',
          PERCENT_COMMISSION: '% av ersättningen',
          CALCULATED_COMMISSION: 'Total ersättning',
          CALCULATION_MODEL: 'beräkningsmodell',
        },
        CONNECTED_ARTICLES: {
          TITLE: 'kopplade artiklar',
          STARTING_FEE_IN_STORE: 'Startavgift i butik',
          STARTING_FEE_AMOUNT: 'Startavgift',
          STARTING_FEE_ARTICLE: 'Artikel startavgift',
          ADDITIONAL_SUBVENTION: 'Extraersättning',
          SUBVENTION_COMMISSION: 'Subvention commission',
          SUBVENTION_ARTICLE: 'Artikel extraersättning',
        },
        INCENTIVES_INFO: {
          TITLE: 'Incitament',
          STAFF_BONUS: 'Personalbonus',
        },
      },
    },
    ORDER: {
      TITLE_LIST: 'Orderlista',
      TITLE_EDIT: 'Ändra order',
      TITLE_NEW: 'Skapa ny order',
      // New contract
      TITLE_TYPE: 'Nytt avtal',
      TITLE_COMMENT: 'kommentarer till order',
      TITLE_LOGGING: 'Loggar ändring till order',
      BTN_CANCELLED: 'Makulera',
      BTN_RESET: 'Återställ',
      BTN_BACK: 'Backa',
      BTN_SAVE: 'Spara',
      BTN_DOWNLOAD: 'Ladda ned',
      BTN_SAVE_COMMENT: 'Lägg till',
      BTN_NEW: 'Ny Order',
      BTN_EDIT: 'Ändra Order',
      BTN_DELETE: 'Radera Order',
      BTN_PREVIEW_INSURANCE_PROPOSAL: 'Preview insurance proposal',
      BTN_PREVIEW_INSURANCE_FINAL_DOCUMENT: 'Activated insurance agreement',
      INSURANCE_PROPOSAL_NOT_RECEIVED: 'Försäkringsdokument har ej inkommit',
      INSURANCE_FINAL_PROPOSAL_NOT_RECEIVED:
        'Slutligt försäkringsdokument har inte tagits emot än',
      CONTINUE_ORDER: 'Continue order',
      ORDER_UUID: 'Order uuid',
      TEMPORARY_ORDER_CREATED: 'Temporary order created',
      TEMPORARY_ORDER_UPDATED: 'Temporary order updated',
      HUNGARY: {
        NEXT_BUTTON: 'Tovább',
        BACK_BUTTON: 'Vissza',

        ORDER_VODAFONE_STATUS: 'Vodafone megrendelés állapota',
        BUDAPEST_BANK_STATUS: 'Budapesti banki megbízás állapota',
        AEGON_INSURACE_STATUS: 'Aegon biztosítási megrendelés állapota',

        SIM_ACTIVATION: {
          ENTER:
            'Kérjük, adja meg újra a SIM-kártya számát a SIM-kártya aktiválásának ellenőrzéséhez',
          ERROR:
            'A megadott SIM-kártya száma nem számolja a tárolt SIM-kártya számát. Kérjük, ellenőrizze a helyes SIM-kártya számát az új szám kétszeri beírásával.',
        },

        STATUSES: {
          RECEIVED: 'A megrendelés beérkezett',
          REGISTRATION_IN_PROGRESS: 'A regisztráció folyamatban van',
          REGISTRATION_APPROVED: 'A regisztráció jóváhagyva',
          REGISTRATION_DECLINED: 'A regisztráció elutasítva',
          INCORRECT_DETAILS: 'Helytelen részletek',
          PENDING: 'Függőben levő',
          OFFER_RECEIVED: 'Az ajánlat beérkezett',
          INSURANCE_APPROVED: 'Biztosítás jóváhagyva',
          INSURANCE_DECLINED: 'A biztosítás csökkent',
        },

        CURRENT_OPERATOR_TITLE_SHORT: 'Current service provider',
        CURRENT_OPERATOR_TITLE: 'Válassza ki a jelenlegi mobilszolgáltatóját',
        CURRENT_OPERATOR_NAME: 'Szolgáltató',
        CURRENT_OPERATOR_MONTHLY_AMOUNT: 'havi összeg',
        CURRENT_OPERATOR_CONTRACT_TYPE: 'Feltöltőkártyás Flotta Előfizetés',

        SUMMARY_BLOCK: {
          DEVICE: 'készülék',
          TOTAL_PRICE: 'Teljes ár',
          PER_MONTH: 'Havonta',
          DEVICE_DISCOUNT: 'készülék kedvezmény',
          CONTRACT: 'Szerződés',
          FINANCE: 'Hitel',
          FINANCE_BLOCK: 'Részletfizetés',
          FINANCE_TO_PAY: 'Önerö',
          TOTAL_AMOUNT: 'Teljes összeg',
          FINANCE_TOTAL_AMOUNT: 'Finanszírozandó összeg',
          INSTALLMENT_PAYMENT: 'Szerződéskötéskor fizetendő',
          INSURANCE: 'Biztosítás',
          TOTAL_21: 'Havi költségek 21 hónapon keresztül:',
          TOTAL_3: 'Havi költségek a 21 hónapot követő 3 hónapban:',
          TOTAL_22: 'Payable 22x per month',
          TOTAL_2: 'Amount to be paid 2x (after 22 months) per month',
          SUBTOTAL: 'Összesen',
          SUBTOTAL_DEVICE: 'Fizetendő Vodafone előfizetéssel',
          SUBTOTAL_INSURANCE: 'Egyéb összesen',
          TO_PAY: 'Önerö',
          ACCESSORIES: 'Tillbehör',
          ADMINISTRATION_FEE: 'Administrationsavgift',
        },

        INSURANCE_DOCUMENT: {
          TITLE: 'szerződés',
          TEXT: 'Innan du fortsätter, Vänligen informera kunden om datahanteringsinformationen och vid behov tillhandahålla den i tryckt form. Om kunden samtycker till att hantera data, Klicka på" Nästa " - knappen.',
          NEXT: 'Következő',
        },

        ACTIVITY_MENU: {
          TITLE: 'Automatisk utloggning',
          TEXT_ONE: 'Du loggas ut om 10 minuter',
          TEXT_TWO: 'Vill du fortsätta vara inloggad?',
          TEXT_THREE: 'För att fortsätta tryck på ”fortsätt vara inloggad',
          STAY: 'Fortsätt vara inloggad',
          LOG_OUT: 'Logga ut',
        },

        INFOVIDEO: {
          BACK_BUTTON: 'Vissza',
          NEXT_BUTTON: 'Tovabb',
          CONFIRM_BUTTON: 'Elfogadom',
          ORDER_TITLE: 'Rendelesi összefoglalás',
          ORDER_TEXT_HEADER: '"Nyilatkozat nélküli infovideo:',
          ORDER_TEXT:
            'Kedves ügyfelünk köszönjük, hogy meghallgattad Kollégánkat, az elöbbi összefoglaló oldalon összegeztük neked azt amiről beszéltetek.A következő kb X percben szeretnénk tájékoztatni minden olyan feltételről téged, ami elengedhetetlen ahhoz, hogy szerződést kössünk egymással. Célunk, hogy minden szükséges feltételt megismerj a lehető legegyszerűbb módon. Ehhez nyújunk segítséget a következő rövid videókkal. Minden video egy nyilatkozat megismerését teszi lehetővé, így videónként szükséges döntened majd. Lesznek olyanok ahol dönthesz úgy, hogy igénybe kívánod-e venni az adott lehetőséget, azonban az itt egy piktogram) ilyen jelölésselellátott videók esetében az IGEN válasz jelölése nélkül a szerződés nem köthető meg, így, ha a videó megtekintését követően további kérdésed merülne fel ezzel a gombbal (piktogramm) ismét megtekintheted azt. Ha a nyilatkozat elolvasását követően mégis kérdésed maratt fordulj bátran kollégáinkhoz."',
          VIDEO_TITLES: [
            '1. Infovideo, mint digitális segítség',
            '2. Csomagajánlatunk (N/A)',
            '3. Választott Vodafone előfizetés (N/A)',
            '4. Magánszemélyként szerződhetek',
            '5. Szükséges dokumentumok',
            '6. Roaming',
            '7. Szerződésmásolat',
            '8. Elektronikus értesítések',
            '9. Felmondási jog',
            '10. Adatvédelmi tájékoztató',
            '11. Hívásrészletező, személyes adatok',
            '12. Egyedi értékhatár',
            '13. Nyilatkozat teljesköru tajekoztasrol',
            '14. Szerződés modositas',
            '15. Szerződés idötartamok',
            '16. Hívásrészletező és tudakozó',
            '17. Direkt marketing hozzájárulás',
            '18. Helymeghatározási adatok',
          ],
          UNDER_VIDEO_TEXTS: [
            'Nyilatkozom, hogy az egyedi Előfizetői Szerződésben foglaltakat - különösen az abban foglalt hivatkozásokat - megismertem és tudomásul veszem. Tudomásul veszem, hogy az Előfizetői Szerződés az egyedi előfizetői szerződésből és az ÁSZF-ből áll, a szolgáltatással kapcsolatos kérdésekben elsősorban az egyedi előfizetői rendelkezések, másodsorban az ÁSZF rendelkezései az irányadóak. Nyilatkozom, hogy az Általános szerződési feltételeket (ÁSZF) megismertem és tudomásul veszem, hogy az az Előfizetői Szerződés részét képezi. Nyilatkozom, hogy az Egyéb Szolgáltatások Szerződési Feltételeit (ESZSZF) megismertem és tudomásul veszem, hogy az az Előfizetői Szerződés részét képezi.',
            'Nyilatkozom, hogy az Előfizetői Szerződés megkötése során a Szolgáltató teljes körű tájékoztatást nyújtott az érvényes barangolási díjakról és a szabályozott barangolásos adatátviteli szolgáltatások díjazására vonatkozóan',
            'Nyilatkozom, hogy az Előfizetői Szerződés megkötése előtt megkaptam a Szolgáltatótól a tájékoztatást arról, hogy az Előfizetői Szerződés fennállása alatt, vagy annak megszűnését követően az Eht. 143. § (2) bekezdése szerinti elévülési időn belül évente egyszer ingyenesen kérhetem az Előfizetői Szerződés általam megjelölt időpontjában hatályos tartalmáról szóló, legalább az Eszr. 11. § (1) bekezdés szerinti elemeket tartalmazó dokumentum átadását.',
            'Nyilatkozom, hogy a Szolgáltató értesítési kötelezettségének - beleértve a felmondást is - az általam megadott elérhetőségeken az Eht. 144. § (4) bekezdés a)-c) pontjában foglaltaknak megfelelően elektronikus úton vagy az elektronikus hírközlési szolgáltatás jellegéhez igazodó módon (különösen: SMS, MMS, telefonhívás) is eleget tehet.',
            'Tájékoztatást megkaptam, hogy az előfizetői szerződésben megjelölt igénybevételi helyen a szolgáltatás minőségének hiányosságaival kapcsolatos felmondási jog gyakorlásának lehetőségéről, feltételeiről és következményeiről az ÁSZF 12.4.2.1(d) és 12.6.9 pontban foglaltak alapján az alábbiak szerint: Az ÁSZF 12.4.2.1. (d) rendelkezése jelenleg: "Vezeték nélküli internet (azaz mobil internet hozzáférési), valamint mobil radiotelefon szolgáltatásra kötött Előfizetői Szerződés esetén Előfizető a szolgáltatás nyújtásának megkezdésétől számított 14 (tizennégy) napon belül a lenti 12.6.9. pontban meghatározott jogkövetkezményekkel azonnali hatállyal felmondhatja, ha az Előfizetői Szerződés további teljesítéséhez fűződő érdeke azon okból szűnt meg, hogy az Előfizetői Szerződésben megjelölt igénybevételi helyen (cím, annak hiányában helyrajzi szám) a Szolgáltatás minősége nem felel meg az Előfizetői Szerződésben foglaltaknak, vagy a Szolgáltatás nem vehető igénybe. A felmondási jog gyakorlásának lehetőségéről, feltételeiről és következményeiről Szolgáltató köteles igazolható módon tájékoztatni az Előfizetőt az Előfizetői Szerződés megkötésével egyidejűleg." Az ÁSZF 12.6.9 rendelkezése jelenleg: "A 12.4.2.1. (d) pontban foglalt felmondás esetén a Szolgáltató csak a szerződéskötéstől a felmondásig eltelt időre járó arányos havidíist valamint fordolomarínunc díiat tartalmazó Coldáltatác acatáhon 27 Flőfizető által tánvlodocon folhasznált fordalom díiát vadu a',
            'Előfizető kijelentem, hogy a Szolgáltatótól az Eht. 159. (1) bekezdésében foglaltakra vonatkozó adatvédelmi tájékoztatást megkaptam, erről részletes információt a ÁSZF 3. számú melléklete tartalmaz.',
            'Tudomásul veszem, hogy a Szolgáltató a 2/2015. (III. 30.) NMHH rendeletben előírt kötelezettségének eleget téve felhívta figyelmemet arra, hogy a részletes számlainformációt tartalmazó kimutatással más felhasználók személyes adatainak birtokába is juthatok. Ezeknek megismerésére csak akkor vagyok jogosult, ha a készüléket kizárólag én használom vagy telefonkészülékem használói - a tájékoztatásukat követően - felém tett nyilatkozattal ehhez hozzájárultak.',
            'Tudomásul veszem, hogy a Szolgáltató a szolgáltatás tekintetében az igénybevétel korlátjaként összeghatárokat, vagy hívás-, üzenet-, adatmennyiséget határozott meg, és ezek túllépése esetén az Eht 137. § (1) bekezdés d) pontja szerint korlátozással élhet.',
          ],
          INFOVIDEO_LOGIN_TITLE: 'Infovideo Bejelentkezés',
          COMPLETED_ALL_STEPS: 'Minden nyilatkozatát rögzítettük!\n Köszönjük!',
        },

        // First step

        FIRST_TITLE: 'Előzetes adatok',
        STORE_CODE: 'Kereskedő kód',
        STORE_NAME: 'Kereskedő neve',
        FINANCE_ADMIN_CODE: 'Hitelügyintéző kódja',
        MARKER: 'Jelölő adat',
        CREDIT_TYPE: 'Hitel típusa',
        CONFIRMATION: 'Jóváhagyásba',
        CMBET: 'Van otthon vezetékes telefon (CMBET)',
        CMBSZZ: 'Bankszámlával igazoly jövedelem? (CMBBSZ)',
        CMBB: 'BB-s bankszámlával igazolt jövedelem? (CMBB)',
        CMBMNJ:
          "Kódok oldal - CMBMNJ ha 'nem' járul hozzá, akkor jelölni kell a kódok oldalon",
        BNPL: 'BNPL',
        CUSTOMER_TYPE_NAME: 'Ügyféltípus neve',
        CREDIT_INTERMEDIARY_AGENT: 'Hitelközvetítő ügynökkód',
        CUSTOMER_WAITING_FOR_REVIEW: 'Ügyfél megvárja a bírálatot?',
        DOES_CLIENT_ACT_ON_ITS_OWN_BEHALF: 'Ügyfél saját nevében jár el?',

        // Second step

        SECOND_TITLE: 'Nyilatkozat adatok',
        HAVE_THE_PUBLIC_CHARGES_BEEN_PAID:
          'Tudomása szerint megfizetésre kerültek a közterhek a jövedelme után?',
        ADD_INCOME: 'Új jövedelem',
        TYPE_OF_INCOME: 'Jövedelem tipusa',
        METHOD_OF_PROOF_OF_INCOME: 'Jövedelemigazolás módja',
        DATE_OF_ISSUE_CERTIFICATE: 'Jövedelemigazolás kiállitásának kelte',
        AMOUNT_OF_MONTHLY_NET_INCOME: 'Havi nettó jövedelem összege',
        CHANGE_COLUMN: 'Edit',
        MODIFY_BUTTON: 'Modify',
        REMOVE_BUTTON: 'Remove',
        ARE_YOU_GUARANTOR:
          'Hozzátartozója által felvett pénzügyi lízingben kezes-e?:',
        AMOUNT_OF_MONTHLY_LEASE:
          '30 napon belül le nem járó, pénzügy lízingszerződésből fakadó havi lízingdíj összege',
        HAS_LOAN_COMBINED_WITH_HOME:
          'Van-e lakás-előtakarékosségi szerződéssel (LTP) kombinált hitele, amely esetén a LTP szerződést nem mondhatja vissza egyoldalúan?:',
        AMOUNT_OF_MONTHLY_FEE_ARISING:
          '30 napon belül le nem járó, LTP szerződésből fakadó havi díj összege:',
        HAS_LOAN_COMBINED_WITH_LIFE:
          'Van-e életbiztosítással kombinált hitele, amely esetén az életbiztosítási szerződést nem mondhatja vissza egyoldalúan?:',
        AMOUNT_OF_MONTHLY_PREMIUM_ARISING:
          '30 napon belül le nem járó, Életbiztosítási szerződésből fakadó havi díj összege',
        CLAIMING_TOTAL_MONTHLY_NET_INCOME: 'Igényõ összes have nettó jövedelem',
        NET_MONTHLY_INCOME_OF_CLAIMING_HOUSEHOLD:
          'Háztartás havi nettó jövedelme (az igénylőén kívül)',
        TOTAL_INCOME: 'Összes jövedelem',

        // Third step

        THIRD_TITLE: 'Személyes adatok',
        ID_CARD_NUMBER: 'Azonosító okmány száma',
        IDENTITY_CARD_TYPE: 'Uj tipusu szelmelyi igazolvany - lakcim kartya',
        DATE_OF_ISSUE: 'A kiállítás dátuma',
        GENDER: 'Neme',
        COUNTRY_OF_CITIZENSHIP: 'Állampolgárság országa',
        TITLE: 'Titulus',
        FIRST_NAME: 'Név / Keresztnév',
        LAST_NAME: 'Név / Vezeték név',
        BIRTH_FIRST_NAME: 'Születési név / Keresztnév',
        BIRTH_LAST_NAME: 'Születési név / Vezetéknév',
        MOTHERS_BIRTH_FIRST_NAME: 'Anyja neve / Keresztnév',
        MOTHERS_BIRTH_LAST_NAME: 'Anyja neve / Vezetéknév',
        DATE_OF_BIRTH: 'Születési idö',
        PLACE_OF_BIRTH: 'Születési hely',
        DOES_CUSTOMER_CONSENT_TO_THE_BANK:
          'Hozzájárul az ügyfél ahhoz, hogy a Bank a hitelbírálathoz kapcsolódóan lekérdezze a pozitív adatait?',
        DOES_CUSTOMER_CONSENT_TO_ANOTHER_BANK:
          'Hozzájárul az ügyfél ahhoz, hogy más bank átvegye az adatait a pozitív KHR-ből?',
        DOES_CLIENT_CONSENT_THE_POSITIVE:
          'Does the client consent to the positive KHR being able to process his data for 5 years after the termination of the contract?',
        PLACE_OF_DECLARATION: 'Nyilatkozat helye',
        DATE_OF_DECLARATION: 'Nyilatkozat dátuma',
        MARTIAL_STATUS: 'Családi állapot',
        EDUCATION: 'Végzettség',
        OCCUPATION_TYPE: 'Foglalkozás tipus',
        NUMBER_OF_SEARCH_ENGINES: 'Keresök száma',
        NUMBER_OF_DEPENDENTS: 'Eltartottak száma',

        // Fourth step

        FOURTH_TITLE: 'Lakcim adatok',
        PERMANENT_ZIP: 'Állandó lakcím / Irányítószám',
        PERMANENT_CITY: 'Állandó lakcím / Város',
        PERMANENT_NAME_OF_PUBLIC_AREA: 'Állandó lakcím / Közterület neve',
        PERMANENT_NAME_OF_PUBLIC_SPACE: 'Állandó lakcím / Közterület jellege',
        PERMANENT_HOUSE_NUMBER: 'Állandó lakcím / Házszám',
        PERMANENT_BUILDING: 'Állandó lakcím / Épület',
        PERMANENT_STAIRCASE: 'Állandó lakcím / Lépcsőház',
        PERMANENT_FLOOR: 'Állandó lakcím / Emelet',
        PERMANENT_DOOR: 'Állandó lakcím / Ajtó',

        RESIDENCE_ZIP: 'Tartózkodási lakcím / Irányítószám',
        RESIDENCE_CITY: 'Tartózkodási lakcím / Város',
        RESIDENCE_NAME_OF_PUBLIC_AREA: 'Tartózkodási lakcím / Közterület neve',
        RESIDENCE_NAME_OF_PUBLIC_SPACE:
          'Tartózkodási lakcím / Közterület jellege',
        RESIDENCE_HOUSE_NUMBER: 'Tartózkodási lakcím / Házszám',
        RESIDENCE_BUILDING: 'Tartózkodási lakcím / Épület',
        RESIDENCE_STAIRCASE: 'Tartózkodási lakcím / Lépcsőház',
        RESIDENCE_FLOOR: 'Tartózkodási lakcím / Emelet',
        RESIDENCE_DOOR: 'Tartózkodási lakcím / Ajtó',
        RESIDENCE_PHONE_NUMBER: 'Tartózkodási lakcím / Telefonszám',
        RESIDENCE_PO_BOX: 'Tartózkodási lakcím / Postafiók',

        COPY_FROM_PERMANENT_BUTTON: 'Copy from permanent',

        PHONE_NUMBER: 'Telefonszám',
        LANDLINE_PHONE_NUMBER: 'Fast telefonnummer',
        PHONE_FOR_SHOPPING: 'Mobiltelefonszám internetes vásárláshoz',
        INTERNETBAN_NUMBER: 'Internetban telefonszám',
        MOBILLBANK_NUMBER: 'Mobilbank telefonszám',
        MOBIL_PHONE_NUMBER: 'Mobil telefonszám',
        EMAIL: 'Email',
        TYPE_OF_MOBILE_CONTRACT: 'Mobilszerződés típusa',
        WHEN_DID_CONTRACT_WITH_MOBILE:
          'Mikor szerződött a mobilszolgáltatóval?',
        HOW_LONG_CURRENT_ADDRESS: 'Mióta lakik? Jelenlegi lakcímén: ',
        HOW_LONG_PROPERTY: 'Mióta lakik? Tulajdon:',

        // Fifth step

        FIFTH_TITLE: 'Kártya adatok',
        CARD_NAME: 'Kártyanév',
        PASSWORD: 'Jelszó',
        NEED_MOBILE_BANK: 'Igényel mobilbankot?',
        COBRAND_ID: 'Cobrand azonosító',

        // Sixth step

        SIXTH_TITLE: 'Munkáltató adatok',
        SELF_EPLOYEED: 'Egyéni vállalkozó-e?',
        BUSINESS_CARD_NUMBER: 'Vállálkózói igazolvány száma (e.v. Esetén)',
        UJKM_CODE: 'Kódok oldal / ÚJKM kód',
        POSITION: 'Pozíció',
        EMPLOYER: 'Munkáltató',
        CURRENT_JOB: {
          COMPANY_NAME: 'Jelenlegi munkahely / Cég neve',
          COMPANY_REGISTRATION_NUMBER: 'Jelenlegi munkahely / Cégjegyzékszám',
          TAX_NUMBER: 'Jelenlegi munkahely / Adószám',
          POSTCODE: 'Jelenlegi munkahely / Irányítószám',
          CITY: 'Jelenlegi munkahely / Város',
          PUBLIC_AREA: 'Jelenlegi munkahely / Közterület neve',
          PUBLIC_SPACE: 'Jelenlegi munkahely / Közterület jellege',
          HOUSE_NUMBER: 'Jelenlegi munkahely / Házszám',
          BUILDING: 'Jelenlegi munkahely / Épület',
          STAIRCASE: 'Jelenlegi munkahely / Lépcsőház',
          FLOOR: 'Jelenlegi munkahely / Emelet',
          DOOR: 'Jelenlegi munkahely / Ajtó',
          PHONE_NUMBER: 'Jelenlegi munkahely / Telefonszám',
          EMPLOYER_EMAIL_ADDRESS: 'Munkáltató e-mail cím',
          SECTOR: 'Jelenlegi munkahely / Szektor',
          OCCUPATION: 'Jelenlegi munkahely / Foglalkozás',
          WORK_PHONE_NUMBER: 'Jelenlegi munkahely / Munkahelyi telefonszám',
          SIDE: 'Jelenlegi munkahely / Mellék',
          BEGINNING_OF_EMPLOYMENT: 'Munkaviszony kezdete',
          END_OF_EMPLOYMENT: 'Munkaviszony vége',
          FIXED_EMPLOYMENT: 'Határozott munkaviszony',
        },
        PREVIOUS_JOB: {
          COMPANY_NAME: 'Előző munkahely / Cég neve',
          POSTCODE: 'Előző munkahely / Irányítószám',
          CITY: 'Előző munkahely / Város',
          PUBLIC_AREA: 'Előző munkahely / Közterület neve',
          PUBLIC_SPACE: 'Előző munkahely / Közterület jellege',
          HOUSE_NUMBER: 'Előző munkahely / Házszám',
          BUILDING: 'Előző munkahely / Épület',
          STAIRCASE: 'Előző munkahely / Lépcsőház',
          FLOOR: 'Előző munkahely / Emelet',
          DOOR: 'Előző munkahely / Ajtó',
          PHONE_NUMBER: 'Előző munkahely / Telefonszám',
          BEGINNING_OF_EMPLOYMENT: 'Munkaviszony kezdete',
          END_OF_EMPLOYMENT: 'Munkaviszony vége',
        },
        NAME_OF_CONTACT_PERSON: 'Kapcsolattartó neve',
        NUMBER_PHONE: 'Télefomszám',
        ATTACHMENT: 'Mellék',
        EMPLOYER_PAYMENT_ACCOUNT_NUMBER: 'Munkáltató fizetési számlaszáma',
        PRIMARY_PRODUCER_CARD_NUMBER: 'Őstermelői igazolvány száma',

        // Seventh step

        SEVENTH_TITLE: 'Biztosito',
        TYPE_OF_INSURANCE: 'Biztosítás típusa',
        NEEDS_THE_INCURANCE: 'Igényli-e',
        CONSTRUCTION: 'Konstrukció',
        DESIGNATION: 'Konstrukció megnevezése',
        OTHER_GROUNDS_FOR_REFUSAL: 'Egyéb elutasítási ok',

        // Eigth step

        EIGHT_TITLE: 'Tranzactio',
        MATURITY: 'Futamidő',
        MY_STRENGTH: 'Sajáterő',
        PURCHASE_PRICE: 'Vételár',
        LOAN_AMOUNT: 'Hitelösszeg',
        BANK_ACCOUNT_NUMBER: 'Bankszámlaszám',
        PRICE_WARRANTY_PRODUCTS: 'Garanciális termékek ára:',
        CREDIT_LINE_REQUIRED: 'Igényelt hitelkeret',
        PAYMENT: 'Fizetési mód',
        INSTALLMENT: 'Törlesztőrészlet',
        TRANSACTION_ID: 'Tranzakció azonosító',
        TYPE_OF_TRANSACTION: 'Tranzakció típusa',
        CUSTOMER_TYPE_CODE: 'Ügyféltípus kódja',
        TRANSACTION_CUSTOMER_TYPE_CODE: 'Tranzakció Ügyféltípus kódja',
        VOUCHER_AMOUNT: 'Utalvány összege',
        PRODUCT_LIST_NAME: 'Termék lista / Megnevezés',
        PIECE: 'Darab',
        UNIT_PRICE: 'Egységár',
        EAN: 'EAN kód',
        AGREEMENT: {
          DOWNLOAD: 'Letöltés',
          PRINT: 'Nyomtatás',
          LOADING_ERROR:
            'Ha nem látja a dokumentumot, próbálja meg újra megnyitni',
        },
        NO_COMMENT: 'Han kommenterar inte',
      },
      REPORT: {
        DOWNLOAD_REPORT: 'Ladda ned rapport',
        FILTER: 'Filtrera',
        SALES_PERSON_ID: 'Säljarnummer',
        DATE: 'Datum',
        OPERATOR: 'Operatör',
        TABLE: {
          OPERATOR: 'Operatör',
          DATE: 'Datum',
          STORE_ID: 'Varuhus ID',
          SALES_PERSON_ID: 'Säljarnummer',
          ORDER_NUMBER: 'Ordernummer',
          CONTRACT: 'Abonnemang',
          PHONE: 'Telefon',
          TOTAL_COMMISSION: 'Total ersättning',
          TOTAL_PURCHASE: 'Totalt inköpspris',
          TOTAL_TO_PAY: 'Totalt att betala',
          GROSS_PROFIT: 'Gross profit',
          BONUS: 'Bonus',
          TOTAL: 'Totalt',
        },
      },
      FORM: {
        PERMANENT_ADDRESS: 'Permanent adress',
        RESIDENCE_ADDRESS: 'Hemadress',
        STATE: 'Status',
        CUSTOMERID: 'Kund ID',
        PHONENUMBER: 'Telefonnummer',
        ADDRESSCARDNUMBER: 'Adresskortnummer',
        BIRTHDATE: 'Födelsedatum',
        FIRSTNAME: 'Förnamn',
        LASTNAME: 'Efternamn',
        ADDRESS: 'Adress',
        ZIPCODE: 'Postnummer',
        CITY: 'Stad',
        EMAIL: 'Epost',
        ACCOUNTBANK: 'Bankkonto',
        BANKNAME: 'Bankens namn',
        IBAN: 'IBAN',
        CANCELLATIONREASON: 'Anledning till makulering',
        DEVICE: 'Enhet',
        SUBVENTION: 'Subvention',
        CONTRACT: 'Abonnemang',
        MONTHLY_COST: 'Månadskostnad',
        MONTHLY_FEE: 'Månadsavgift',
        COMMENT: 'Kommentar',
        SUBSCRIPTION_PHONE_NUMBER: 'Telefonummer abonnemang',
        ACTIVATION_DATE: 'Porteringsdatum',
        IMEI_NUMBER: 'IMEI nummer',
        CUSTOMER_CODE: 'Customer code',
        SALES_PRICE: 'Försäljningspris',
        ACCESSORY: 'Tillbehör',
        GENDER: 'Kön',
        //
        MOTHERS_BIRTH_NAME: 'Mothers birth name',
        BIRTH_NAME: 'Birth name',
        PLACE_OF_BIRTH: 'Születési hely',
        COUNTRY_OF_CITIZENSHIP: 'Állampolgárság országa',
        IDENTITY_CARD_TYPE: 'Indentity card type',
        HOUSE_NUMBER: 'House number',
        NAME_OF_PUBLIC_AREA: 'Name of public area',
        NAME_OF_PUBLIC_SPACE: 'Name of public space',
        BUILDING: 'Building',
        STAIRCASE: 'Staircase',
        ID_CARD_NUMBER: 'Id card number (Huvudbokstäver och siffror)',
        FLOOR: 'Floor',
        DOOR: 'Door',
        RESIDENCE_CARD: 'Residence card (Huvudbokstäver och siffror)',
        MANUFACTURER_WARRANTY_TIME: 'Tillverkarens garantiperiod (månad)',
        ADDRESS_DETAILS: 'Address details',
        LINK_OPERATOR: 'Länk till operatör',
        SUMMARY_BONUS_AMOUNT: 'Summa bonusbelopp',
        SUMMARY_TO_PAY: 'Summa att betala',
        MONTHLY_DISCOUNT: 'Månadsrabatt',
        IS_EMPLOYED: 'Är anställd?',
        CONTRACT_PHONE_NUMBER: 'Előfizetés',
        SHOW_USE_ADDRESS_CHECKBOX: 'Use residence address',
        COPY_FROM_PERMANENT_ADDRESS: 'Copy from permanent address',
        VIEW: 'View',
        INSURANCE: 'Insurance',
        SCREENPROTECTOR: 'Screen Protector',
        NEW_PHONE_NUMBER: 'Nytt telefonnummer',
        ADDITIONAL_PRODUCT: 'Ytterligare produkt',
      },
      VALIDATION: {
        INVALID: '{{name}} ej giltigt',
        REQUIRED: '{{name}} är nödvändigt',
        MIN_LENGTH: '{{name}} minsta längd är {{min}}',
        AGREEMENT_REQUIRED: 'Acceptera vilkor är nödvändigt',
        NOT_FOUND: 'Begärt {{name}} hittades ej',
        INVALID_LOGIN: 'Felaktiga inloggningsuppgifter',
        REQUIRED_FIELD: 'Obligatoriskt fält',
        MIN_LENGTH_FIELD: 'Minsta längd fält:',
        MAX_LENGTH_FIELD: 'Maximal längd fält:',
        INVALID_FIELD: 'Fältet är inte giltigt',
        FORBIDDEN_SYMBOLS_FIELD: 'Fältet innehåller förbjudna karaktärer',
        CONTRACT_PHONE_NUMBER_TO_BE_FILLEDOUT: 'Contract to be filled out',
        VODAFONE_STATUS_SELECT_REASON: 'Please, select decline reason.',
      },
      LIST_TABLE: {
        TITLE: 'Orders',
        ORDER_NUMBER: 'Order number',
        OPERATOR: {
          TITLE: 'Operatör',
        },
        SALES: {
          TITLE: 'Säljarnummer',
        },
        STORE_ID: {
          TITLE: 'Varuhus ID',
        },
        PROVIDER_ORDER_ID: {
          TITLE: 'Operatörs order ID',
        },
        DATE: {
          TITLE: 'Datum',
        },
        GRABBED_BY: {
          TITLE: 'Hanterad av',
        },
        STATUS: {
          TITLE: 'Status',
          ACTIVE: 'Du kan redigera',
          GRABBED: 'Redigerat av användare',
          HOT: 'Över 72 timmar',
        },
        STATE: {
          TITLE_FILTER: 'Filtrera status',
          TITLE: 'Status',
          ALL: {
            FULL: 'Alla ordrar',
            SHORT: 'Alla',
          },
          NEW: {
            FULL: 'Nya ordrar',
            SHORT: 'Nya',
          },
          PENDING: {
            FULL: 'Ordrar under bearbetning',
            SHORT: 'Bearbetning',
          },
          COMPLETED: {
            FULL: 'Avslutade ordrar',
            SHORT: 'Avslutade',
          },
          CANCELLED: {
            FULL: 'Borttagen (Cancelled)',
            SHORT: 'Cancelled',
          },
          RECEIVED: {
            FULL: 'Order mottagen (Inväntar kreditkontroll)',
            SHORT: 'Mottagen',
          },
          CREDIT_CHECK_OK: {
            FULL: 'Kreditkontroll OK (Inväntar signatur)',
            SHORT: 'Kreditkontroll OK',
          },
          READY_FOR_SHIPMENT: {
            FULL: 'Redo för plock (Stängd)',
            SHORT: 'Stängd',
          },
          PROPOSAL: {
            FULL: 'Proposal',
            SHORT: 'Proposal',
          },
          INFO_VIDEO: {
            FULL: 'Info Videos pågår',
            SHORT: 'Pågår',
          },
        },
        FIRST_NAME: 'Förnamn',
        LAST_NAME: 'Efternamn',
        PHONE_NUMBER: 'Telefonnummer',
        EMAIL: 'Epost',
        STORE: 'Varuhus',
        HEADQUARTER: 'Huvudkontor',
        ACTIONS: {
          TITLE: 'Actions',
          BTN_EDIT: 'Ta order',
          BTN_DELETE: 'Radera order',
        },
      },
      LOG_TABLE: {
        NUMBER: 'Nummer',
        ACTOR: 'Användare',
        CHANGES: {
          TITLE: 'Ändringar',
          CARD_KEY: {
            OLD: 'Gammal',
            NEW: 'Ny',
          },
        },
        DATE_CHANGES: ' Ändringsdatum',
        INSURANCESTATUS: 'Försäkringsstatus',
        CONTRACTPHONENUMBER: 'Kontraktstelefonnummer',
        OPERATORSTATUS: 'Operatörsstatus',
        OPERATORREJECTIONREASON: 'Operatörsavvisande skäl',
      },
      ORDER_DIALOG: {
        BTN_CANCEL: 'Makulera order',
        BTN_NOT_CANCEL: 'Ångra inte',
        BTN_CLOSE: 'Ångra',
        BTN_SAVE: 'Spara',
        REASON_CLOSING: {
          TITLE: 'Vänligen ange en kort förklaring',
          COMMENT: 'Vänligen ange kommentar',
        },
        CONTRACT_DIALOG: {
          TITLE: 'Ändra abonnemang',
        },
        PHONE_DIALOG: {
          TITLE: 'Ändra enhet',
          INPUT: 'Ange telefonens namn ',
        },
        ENTER_VENDO_ID: 'Ange ditt Vendo användarid',
        CHOOSE_STORE: 'Vilken butik jobbar du i?',
        CONFIRM_STORE: 'Bekräfta butik',
      },
      DISCOUNT_CONFIRM: {
        TITLE: 'Rabattbekräftelse',
        TEXT: 'Vill du ge',
        DISCOUNT: 'rabatt?'
      },
    },
    DASHBOARD: {
        TITLE: 'Instrumentbräda',
        FILTERS: {
          HEADQUARTER: 'Huvudkvarter',
          START_DATE: 'Startdatum',
          END_DATE: 'Slutdatum',
          PROVIDER: 'Operatör',
          STORE: 'Butik',
          EMPLOYEE: 'Anställd',
        }
      },
    CATALOG: {
      CURRENCY: 'kr',
      SEARCH_DEVICE: 'Sök enhet',
      SEARCH_PHONE: 'Sök telefonmodell',
      CLICK_OPERATOR_CALL:
        'Klicka på operatör vid försäljning av enbart abonnemang utan telefon',
      DETAILS_PHONE: {
        CONTRACT: {
          TITLE: 'Abonnemang',
          TITLE_EDIT: 'Ändra abonnemang',
          TYPE_CONTRACT: {
            NEW: 'Nytt abonnemang',
            EXTENSION: 'Förlängning abonnemang',
          },
          DURATION_CONTRACT: {
            MONTH: 'Månader',
            oneYear: 'ett år',
            twoYears: 'Två år',
            threeYears: 'Tre år',
          },
          TABLE: {
            TITLE_NAME: 'Abonnemang',
            MONTHLY_COST_CONTRACT: 'Månadskostnad abonnemang',
            MONTHLY_COST_DEVICE: 'Månadskostnad telefon',
            MONTHLY_COST_TOTAL: 'Total månadskostnad',
          },
        },
        SUMMARY_BLOCK: {
          MODIFY: 'Ändra',
          PHONE: 'Telefon',
          PAY_NOW: 'Att betala nu',
          MONTHS: 'Månader',
          MONTH: 'mån',
          CONTRACT: 'Abonnemang',
          ADD_ON: 'Tillägg',
          TO_PAY: 'Att betala',
          ONE_TIME_FEE: 'Engångskostnad 1:a fakturan',
          TOTAL_MONTHLY_COST: 'Total månadskostnad',
          MINIMUM_TOTAL_COST: 'Minsta totalkostnad {{contractLength}} månader',
            TELIA: {
                EXPLANATION: 'Effektiva räntan (den totala kostnaden för delbetalning) med representativt exempel från 2020-12-01:' +
                    ' Nyttjad kontokredit på 10 000 kr till 0 % kreditränta (bunden) med en återbetalningstid på 24 mån, med 24 avbetalningar' +
                    ' om 416,66 kr/mån ger en effektiv ränta på totalt 0 % med e-faktura/e-postfaktura (0 kr) eller 11,15 % med pappersfaktura (49kr/st).' +
                    ' Totalt att återbetala för kontokredit om 10 000 kr är alltså 10 000 kr om du har digital faktura och 11 127 kr om du har pappersfaktura.'
            }
        },
        DATA_USAGE: {
          TITLE: 'Dataanvändning (frivillig uppgift)',
          CALC: {
            TITLE: 'Användning per dag',
            video_streaming: 'Video streaming',
            social_media: 'Sociala Medier',
            music_streaming: 'Musik streaming',
            web_surfing: 'Web surfing ',
            HOURS_PER_DAY: 'timmar per dag',
          },
          SIMPLE: {
            TITLE: 'Användning per månad',
            SELECT_UNLIMITED: 'Obegränsat',
          },
          TOTAL_DATA: 'Total Data',
          UNLIMITED: 'Obegränsat',
        },
        device: {},
        PROVIDER: {
          TITLE: 'Operatör',
          SORT_BY: {
            TITLE: 'Sortera',
            NAME: 'Namn operatör',
            PRICE: 'Pris',
            GB: 'GB',
          },
          SELECT: 'Välj',
          SELECTED: 'Vald',
          MORE_INFO: 'Mer info',
          SHOW_OPTIONS: 'Visa alternativ',
          HIDE_OPTIONS: 'Dölj alternativ',
          MONTHS_COMMITMENT: ' månaders bindningstid',
          NO_COMMITMENT: 'Ingen bindningstid',
        },
        CARD: {
          TITLE: 'Sammanfattning',
          DISCOUNT: 'Rabatt',
          TO_PAY: 'Att betala',
          INSTALMENT_FEE: 'Delbetalningsavgift',
          TO_PAY_OWN_RESOURCES: 'Betalning av egna medel',
          TOTAL_PAY: 'Totalt att betala',
          TYPE_SUM_TO_PAY: 'Type sum to pay',
          MAX_TO_PAY_ERROR: 'Max sum to pay: {{toPay}}',
          CHECKOUT: 'Gå vidare',
          ONE_MORE: 'Lägg till en ny produkt',
          REMOVE_DEVICE: 'Ta bort',
          MONTHLY_DISCOUNT: 'Månadsrabatt',
          NO_FINANCE: 'No financing"',
          ADDITIONAL_INFO: 'Ytterligare info',
          MARGIN: 'TB'
        },
        ADDITIONAL_DEVICE: {
          ADD_SUB_DEVICE: 'Lägg till underenhet',
        },
        CUSTOM_SALES_DISCOUNT: {
          ERR_MSG_PERCENT: 'Endast procenten från 0 till 100',
          ERR_MSG_AMOUNT: 'Endast antalet inte större än enhetspriset',
        }
      },
      CHECKOUT: {
        TITLE: 'Checkout',
        TITLE_DONE: 'Order avslutad',
        BACK: 'Bakåt',
        NEXT: 'Gå vidare',
        SAVE_ORDER_PROPOSAL: 'Save order proposal',
        FINISH_CHECKOUT: 'Skicka order',
        ADD_TO_CART: 'Lägg till kundvagn',
        GET_PERSONAL_DATA: 'Hämta uppgifter',
        IDENTIFY_WITH_BANKID: 'Identifiera med BankID',
        MODIFY_PERSONAL_DATA: 'Redigera',
        SAVE_PERSONAL_DATA: 'Spara',
        CONTRACT_TITLE: 'Abonnemang',
        SEARCH_CONTRACT: 'Sök abonnemang',
        TARIFF_TABLE: 'Tariff Table',
        CUSTOMER_LOYALTY: 'Kundlojalitet',
        NO_LOYALTY: 'Kunden har inga abonnemang',
        SUBSCIPTION_INFO: {
            COMMITMENT: 'Bindningstid',
            PAID_UNTIL: 'Betalas till',
            HARDWARE: 'Hårdvara',
        },
        EXTRA: {
            TITLE: 'Extras',
            OFFERINGS: 'Extra erbjudanden',
            USERS_DOES_NOT_SUPPORT: 'Detta abonnemang stödjer inte extra användare',
            USERS: 'Extra användare',
            YOU_HAVE_EXISTING_SUBSCRIPTION: 'Du har befintliga abonnemang, vill du inkludera dem i din familj?',
            CURRENT: 'Nuvarande',
            AFTER_INCLUSION_IN_FAMILY: 'Efter inkludering i familj',
            INCLUDE_IN_FAMILY: 'Inkludera i familj?',
        },
        FINISH: {
          CUSTOMER_INFO: 'Kundinformation',
          SUMMARY_INFO: 'Summanfattning av din order :',
          CONTRACT_ACTIVATION_INFO: 'Information om hårdvara :',
          SIM_CONTRACT_INFO: 'Aktivering och nummerval :',
          DETAILS: 'Detaljer :',
          INFORMATION_ABOUT_EXTRA_USER: 'Information om extra användare :',
          SIM_SENT_BY_POST: 'Sim skickas med post',
          EMAIL_SENT: 'Epost skickat',
        },
        OVERVIEW: {
          CAMPAIGNS: 'Kampanjer',
          ADDITIONAL_PRODUCTS_ARE_AVAILABLE_FOR_SELECTED_CONTRACT:
            'Dessa tillägg finns tillgängligt för det abonnemang du valt',
          INSURANCES_AVAILABLE_FOR_SELECTED_CONTRACT:
            'Biztosítások a kiválasztott szerződéshez',
          NO_AVAILABLE_FOR_SELECTED_CONTRACT:
            'Det finns inga valbara tillägg till detta abonnemang',
          MONTH: 'mån',
          PERSONS: 'Personer',
          SELECT: 'Välj',
          UNSELECT: 'Ändra',
          SELECTED: 'Vald',
          TITLE: 'Tillägg',
          INSURANCE: 'Insurances: Screen protector',
          TABLE: {
            ARTICLE: 'Artikel',
            NAME: 'Namn',
            QTY: 'Antal',
            PRICE: 'Pris',
          },
          INTERNAL_INFO: {
            TITLE: 'Internal info',
          },
        },
        OFFERING_SUMMARY: {
          TITLE: 'Offering Summary',
        },
        SUCCESS: {
          MESSAGE: 'Din order är lagd. ordernummer: ',
          NEXT: 'Till Catalog',
          BACK: 'Stanna på denna sida',
          ICONS: {
            LINK: 'Visa länk',
            PHOTO_CAMERA: 'Visa QR kod',
            EMAIL: 'Skicka mail med avtal',
            ASSIGNMENT: 'Väntar på signering',
          },
        },
        CHOOSE_NUMBER_BLOCK: {
          YOUR_NUMBER: 'Välj ditt nummer',
          GET_NEW_NUMBER: 'Nytt nummer',
          ASSIGNED_NUMBER_BY_PROVIDER:
            'Du kommer att tilldelas ett nytt nummer av den operatör du valt',
          ASSIGNED_TELEPHONE_NUMBER: 'Tilldelat telefonnummer',
          KEEP_EXISTING_NUMBER: 'Behåll nummer',
          TRANSFER_OF_NUMBERS: 'Överlåtelse av nummer',
          PORTING_NUMBER_NOTICE:
            'Vid byte av operatör flyttas ditt nuvarande telefonnummer till den operatör du valt. Ditt abonnemang hos din nuvarande operatör avslutas automatiskt. Det kan ta upp till en vecka innan nummerflytten sker. Har du bindningstid och/eller uppsägningstid kvar hos din nuvarande operatör kan du få en slutfaktura.',
          MY_EXISTING_NUMBER: 'Mitt nuvarande telefonnummer - 46712345678',
          MOVE_NUMBER: 'Portera nummer från annan operatör',
          YES: 'Ja',
          SELECT_PREFERRED_NUMBER: 'Välj ett nummer',
          SIM_DELIVERY: 'Leveransmetod SIM',
          ICC_NUMBER: 'ICC nummer',
          START_DATE: 'Startdatum',
          ACTIVATION_DATE: 'Aktiveringsdatum',
        },
        PRICE: {
            TITLE: 'Priser',
        },
        DETAILS: {
          TITLE: 'Detaljer',
          TITLE_CUSTOMER_INFORMATION: 'Kundinformation',
          TITLE_BANK_ACCOUNT: 'Bankkontonummer',
          TITLE_SUBSCRIPTION: 'Abonnemangsinformation',
          SUBSCRIPTION: 'Flytta nummer från annan operatör',
            BANK_ID: {
                SKIP: 'Hoppa över BankID-verifiering',
                PREFERRED_METHOD: 'Föredragen identifieringsmetod:'
            },
          FORM: {
            CUSTOMERID: 'Kund ID',
            ADDRESSCARDNUMBER: 'Adresskortnummer',
            BIRTHDATE: 'Födelsedatum',
            MOTHERNAME: 'Mors namn',
            FIRSTNAME: 'Förnamn',
            LASTNAME: 'Efternamn',
            ADDRESS: 'Adress',
            ZIPCODE: 'Postnummer',
            CITY: 'Stad',
            PHONENUMBER: 'Telefonnummer',
            EMAIL: 'Epost',
            ACCOUNTBANK: 'Bankkonto',
            BANKNAME: 'Bankens namn',
            IBAN: 'IBAN',
            SUBSCRIPTIONPHONENUMBER: 'Telefonnummer abonnemang',
            PROVIDER: 'Operatör',
            SSN: 'Personnummer',
            SSN_12_digits: 'Personnummer 12 siffror'
          },
          VALIDATION: {
            INVALID: '{{name}} ej giltigt',
            REQUIRED: '{{name}} är obligatoriskt',
            MIN_LENGTH: '{{name}} minsta längd är {{min}}',
            AGREEMENT_REQUIRED: 'Acceptera villkor är nödvändigt',
            NOT_FOUND: 'Begärt {{name}} hittades ej',
            INVALID_LOGIN: 'Felaktiga inloggningsuppgifter',
            REQUIRED_FIELD: 'Obligatoriskt fält',
            MIN_LENGTH_FIELD: 'Minsta längd fält:',
            MAX_LENGTH_FIELD: 'Maximal längd fält:',
            INVALID_FIELD: 'ej giltigt fält',
            FORBIDDEN_SYMBOLS_FIELD: 'Fältet innehåller förbjudna karaktärer',
          },
          DIALOG: {
                BANK_ID_VALIDATION: {
                    WAITING_CUSTOMER: 'Väntar på kundens BankID verifiering',
                    WAITING: 'Väntar på säljarens BankID verifiering',
                    TIMEOUT: 'BankID timeout, vänligen försök igen',
                    FAILED: 'BankID validering misslyckades',
                    TELIA: 'Ange ditt Tholbox användarid',
                    TELE2: 'Skanna qr med BankId för att ange ditt Vendo användarid',
                }
            },
        },
        ACTIVATION: {
          TITLE: 'Aktivering och nummerval',
          IMEI_NUMBER: 'IMEI nummer',
          SIM_NUMBER: 'Simkortsnummer',
          SUBSCRIPTION_NUMBER: 'Abonnemangsnummer',
          AGREEMENT_NUMBER: 'Avtalsnummer',
            USER_INFORMED: 'Jag har upplyst kunden om följande:',
            MANUAL: 'Lägg order manuellt',
            MANUAL_CONFIRM: 'Kryssa i nedan om du måste lägga order manuellt i Vendo (Ej API order)',
            TELIA_MANUAL_CONFIRM: 'Kryssa i nedan om du måste lägga order manuellt i Tholbox (Ej API order)',
            TELIA: {
                CUSTOMER_AGREEMENT_TEXT: '0m det behövs för att genomföra köpet kommer en kreditupplysning inhämtas'
            }
        },
        DONE: {
          TITLE: 'Sammanfattning',
        },
        BANNER: {
            CAMPAIGN: 'Kampanj',
            ACTIVE: 'är aktiv just nu',
        }
      },
    },
    STAFF_BONUS: {
        TITLE: 'Personalbonus',
        EXPORT_ACTION: 'Exportera till xls',
        FILTERS: {
            HEADQUARTER: 'Huvudkvarter',
            START_DATE: 'Startdatum',
            END_DATE: 'Slutdatum',
            GROUP_BY: 'Gruppera efter',
            STORE: 'Butik',
            EMPLOYEE: 'Anställd',
        },
        TABLE: {
            COLUMN_NAMES: {
                DATE: 'Datum',
                NAME: 'Namn',
                STORE: 'Butik',
                NUMBER_OF_ORDERS: 'Antal ordrar',
                TOTAL: 'Totalt',
                BONUS: 'Bonus',
            }
        }
    },
    CAMPAIGNS: {
          LIST: {
              TITLE: 'Campaigns list',
              FILTERS: {
                  HEADQUARTER: 'Headquarter',
                  NAME: 'Campaign name filter',
                  STATUS: 'Status filter',
                  STORE: 'Store',
              },
              TABLE: {
                  COLUMN_NAMES: {
                      NAME: 'Campaign name',
                      START_DATE: 'Start date',
                      END_DATE: 'End date',
                      TYPE: 'Type',
                      STORES: 'Stores',
                      STATUS: 'Status',
                      ACTIONS: 'Actions'
                  },
                  PAGINATOR: {}
              },
              ACTIONS: {
                  ADD: 'Add new campaign',
                  PAUSE: 'Pause',
                  UNPAUSE: 'Resume',
                  APPROVE: 'Approve',
                  DUPLICATE: 'Duplicate',
                  DELETE: 'Delete',
              },
          },
          EDIT: {
              TITLE: {
                  CREATE: 'Create campaign',
                  EDIT: 'Edit campaign',
              },
              ACTIONS: {
                  UPLOAD_PDF: 'Upload campaign PDF',
                  SAVE: 'Save',
              },
              HEADQUARTER: 'Select headquarter',
              STORE: 'Select store',
              NAME: 'Campaign name',
              START_DATE: 'Start date',
              END_DATE: 'End date',
              DESCRIPTION: 'Campaign description (optional)',
              TYPE: {
                  TITLE: 'Type',
                  DISCOUNT: 'General discount',
                  BUNDLING: 'Bundling',
              },
              CRITERIA: {
                  TITLE: 'Criteria',
                  PRODUCT_GROUP: 'Product group',
                  DEVICES: 'Devices',
                  DEVICE_VALUE: 'Device value more than',
                  PROVIDER: 'Provider',
                  CONTRACT: 'Contract',
              },
              DISCOUNT: {
                  TYPE: 'Typ av rabatt (% eller värde)',
                  AMOUNT: 'Rabatt',
                  OPTION: {
                    PERCENT: 'procent',
                    VALUE: 'värde'
                }
              },
              BUNDLINGS: {
                  TITLE: 'Select product',
                  PRODUCT: 'Bundling product',
                  PRODUCTS_ALLOWED: '№ of products allowed',
                  VALUE_UP_TO: 'Products for a value up to',
              },
              CAN_BE_COMBINED: 'Kan kombineras med andra kampanjer',
              CAN_APPLY_ANOTHER_DISCOUNT: 'Kan tillämpa ytterligare en rabatt',
          }
      },
    HEADQUARTERS: {
        TITLE: {
            NEW: 'Create headquarter',
            EDIT: 'Edit headquarter'
        },
        EDIT: {
            ACTIONS: {
                BACK: 'Back',
                RESET: 'Reset',
                SAVE: 'Save',
            },
            BASIC_INFO: {
                LABEL: 'Basic info',
                NAME: {
                    EDIT: 'Enter name',
                    TITLE: 'Name',
                    HINT: `Please enter Name`,
                    ERROR: {
                        REQUIRED: `Name is required`,
                        PATTERN: `Name contains invalid characters`,
                    }
                },
            }
        },
    },
    SETTINGS: {
      TITLE: 'Inställningar',
      TABLE: {
        TITLE: {
          CATEGORIES_SUPPORT: 'Kategorier stöd',
          CONTRACT_GROUP: 'Kontraktsgrupp',
          DEVICE_GROUP: 'Enhetsgrupp',
        },
        CONTENT: {
          NEW_CATEGORY: 'Ny kategori...',
        },
      },
      LOADING: {
        CONTRACT_GROUP: 'Kontraktsgrupp laddar',
        DEVICE_GROUP: 'Enhetsgrupp laddar',
      },
      BUTTON: {
        UPDATE: 'Uppdatera'
      }
      },
    ITEMS_PER_PAGE_LABEL: 'Antal per sida',
    CUSTOMER_DATA: 'Customer data',
    DISCOUNT_CONTRACT: 'Discount contract',
    TO_PAY_FLAT: 'To pay',
    MONTHLY_COST_FLAT: 'Monthly Cost',
    GRABBED_FLAT: 'Grabbed',
    SIM_CARD_ACTIVATE: 'SIM card activation',
    SIM_CARD_ACTIVATED: 'SIM-kort aktiverat',
    CONTRACTS_FLAT: 'Contracts',
    INFORMATION_LEAFLETS: 'Information leaflets',
    ORDER_STATUS_VODAFONE_NEW: 'New review',
    NEW: 'New review',
    ORDER_STATUS_VODAFONE_SUCCESSFUL_REVIEW: 'Successful Vodafone review',
    SUCCESSFUL_REVIEW: 'Successful Vodafone review',
    ORDER_STATUS_VODAFONE_UNSUCCESSFUL_REVIEW: 'Unsuccessful Vodafone review',
    UNSUCCESSFUL_REVIEW: 'Unsuccessful Vodafone review',
    ORDER_STATUS_VODAFONE_INCORRECT_DETAILS: 'Incorrect details',
    INCORRECT_DETAILS: 'Incorrect details',
    ORDER_STATUS_BUDAPEST_BANK_NEW: 'New request',
    ORDER_STATUS_BUDAPEST_BANK_CREDIT_RAITING_IN_PROGRESS:
      'Financing credit assessment in progress',
    ORDER_STATUS_BUDAPEST_BANK_SUCCESSFUL_LOAN_REQUEST:
      'Successful loan application',
    ORDER_STATUS_BUDAPEST_BANK_UNSUCCESSFUL_LOAN_REQUEST_OFFER_CANCELLATION:
      'Unsuccessful loan application - Offer cancellation',
    ORDER_STATUS_BUDAPEST_BANK_UNSUCCESSFUL_LOAN_REQUEST_CASH_PURCHASE:
      'Unsuccessful loan application - Cash purchase',
    ORDER_STATUS_BUDAPEST_BANK_NO_FINANCING: 'No financing',
    ORDER_STATUS_TO_HANDLE: 'To Handle',
    ORDER_STATUS_DELETED_OFFER: 'Deleted offer',
    ORDER_STATUS_INCORRECT_DETAILS: 'Incorrect details',
    ORDER_STATUS_OFFERING_CANCELLED: 'Offering cancelled',
    CHECK_STATUS: 'Check status',
    CHECK_STATUS_TOOLTIP:
      '•Ellenőrizd, hogy a+n behívásra váró vásárló minden\n' +
      ' igényelt szolgáltatás és dokumentuma rendelkezésre áll (Vodafone, G+, BB, MM)!',
    INFO_2_TOOLTIP:
      "•Kattints az „MCC+n' dokumentum létrehozása” gombra,\n" +
      ' majd nyomtasd ki a rendszerből!',
    INFO_3_TOOLTIP:
      '•Hívd be a vásárlót a Linistry\n' +
      ' segítségével pulthoz és azonosítsd!',
    INFO_4_TOOLTIP:
      '•GDPR nyilatkozat és Vodafone szolgáltatói\n' +
      ' szerződés ELEKTRONIKUS aláírása',
    INFO_5_TOOLTIP:
      '•Kasszázd a szükséges tételeket\n ' +
      '(átvételi bizonylat, Garancia Plusz)!',
    INFO_6_TOOLTIP:
      '•Ellenőrizd a számát, majd aktiváld az \n ' +
      ' átadott SIM kártyát a vásárló részére!',
    INFO_7_TOOLTIP:
      '•Érvényesítsd a termék(ek) jótállási jegyét,\n' +
      ' kötvényesítsd a Garancia Plusz-t és add ki a terméke(ke)t a vásárlónak!',
    INFO_8_TOOLTIP:
      '•Ellenőrizd hogy a termék és a\n' +
      ' dokumentumok aláírásra, átadásra kerültek!',
    SIGN_OUT: 'Sign Out',
    CARD_TITLE_INFORMATION: 'Information!',
    CLOSE_ORDER_TITLE: 'Close order and activate insurance',
    ORDER_CLOSE_BUTTON: 'Order ready (Closed)',
    LIMIT_ACCESSORY_MSG: 'Du kan inte välja mer än 5 tillbehör',
    deposit: 'Deposition',
  },
};
