import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { HttpExtenstionsModel } from './http-extentsions-model';
import { QueryParamsModel } from './query-models/query-params.model';
import { QueryResultsModel } from './query-models/query-results.model';
import { BaseModel } from './_base.model';
import { skip } from 'rxjs/operators';

export class BaseDataSource implements DataSource<BaseModel> {
  entitySubject = new BehaviorSubject<any[]>([]);
  hasItems: boolean = true;
  loading$: Observable<boolean>;
  isPreloadTextViewed$: Observable<boolean> = of(true);
  paginatorTotalSubject = new BehaviorSubject<number>(0);
  paginatorTotal$: Observable<number>;
  subscriptions: Subscription[] = [];

  constructor() {
    this.paginatorTotal$ = this.paginatorTotalSubject.asObservable();

    const hasItemsSubscription = this.paginatorTotal$
      .pipe(skip(1))
      .subscribe((res) => (this.hasItems = res > 0));
    this.subscriptions.push(hasItemsSubscription);
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.entitySubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.entitySubject.complete();
    this.paginatorTotalSubject.complete();
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  baseFilter(
    _entities: any[],
    _queryParams: QueryParamsModel,
    _filtrationFields: string[] = [],
  ): QueryResultsModel {
    const httpExtention = new HttpExtenstionsModel();
    return httpExtention.baseFilter(_entities, _queryParams, _filtrationFields);
  }

  sortArray(
    _incomingArray: any[],
    _sortField: string = '',
    _sortOrder: string = 'asc',
  ): any[] {
    const httpExtention = new HttpExtenstionsModel();
    return httpExtention.sortArray(_incomingArray, _sortField, _sortOrder);
  }
}
