import { BaseModel } from '../../_base/crud';

export class StoreEmployeeModel extends BaseModel {
  id: number;
  storeId: number;
  employee: number;
  email: string;

  clear(storeId: number) {
    this.id = undefined;
    this.storeId = storeId;
    this.email = '';
    this.employee = undefined;
  }
}
