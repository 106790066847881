// Angular
import { Injectable } from '@angular/core';
// RxJS
import { of } from 'rxjs';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import {
  LayoutUtilsService,
  MessageType,
  QueryResultsModel,
} from '../../_base/crud';
// Services
import { HeadquarterEmployeesService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
  HeadquarterEmployeeActionTypes,
  HeadquarterEmployeesPageRequested,
  HeadquarterEmployeesPageLoaded,
  ManyHeadquarterEmployeesDeleted,
  OneHeadquarterEmployeeDeleted,
  HeadquarterEmployeesPageToggleLoading,
  HeadquarterEmployeeUpdated,
  HeadquarterEmployeeCreated,
  HeadquarterEmployeeOnServerCreated,
} from '../_actions/headquarter-employee.actions';

@Injectable()
export class HeadquarterEmployeeEffects {
  // showLoadingDistpatcher = new ProcutEmployeesPageToggleLoading({ isLoading: true });
  hideLoadingDistpatcher = new HeadquarterEmployeesPageToggleLoading({
    isLoading: false,
  });

  @Effect()
  loadHeadquarterEmployeesPage$ = this.actions$.pipe(
    ofType<HeadquarterEmployeesPageRequested>(
      HeadquarterEmployeeActionTypes.HeadquarterEmployeesPageRequested,
    ),
    mergeMap(({ payload }) =>
      this.headquarterEmployeesService.findHeadquarterEmployees(
        payload.page,
        payload.headquarterId,
      ),
    ),
    map((result: QueryResultsModel) => {
      return new HeadquarterEmployeesPageLoaded({
        headquarterEmployees: result.items,
        totalCount: result.totalCount,
      });
    }),
  );

  @Effect()
  deleteHeadquarterEmployee$ = this.actions$.pipe(
    ofType<OneHeadquarterEmployeeDeleted>(
      HeadquarterEmployeeActionTypes.OneHeadquarterEmployeeDeleted,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new HeadquarterEmployeesPageToggleLoading({ isLoading: true }),
      );
      return this.headquarterEmployeesService.deleteHeadquarterEmployee(
        payload.headquarterId,
        payload.employeeId,
      );
    }),
    map(() => {
      return this.hideLoadingDistpatcher;
    }),
  );

  @Effect()
  deleteHeadquarterEmployees$ = this.actions$.pipe(
    ofType<ManyHeadquarterEmployeesDeleted>(
      HeadquarterEmployeeActionTypes.ManyHeadquarterEmployeesDeleted,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new HeadquarterEmployeesPageToggleLoading({ isLoading: true }),
      );
      return this.headquarterEmployeesService.deleteHeadquarterEmployees(
        payload.ids,
      );
    }),
    map(() => {
      return this.hideLoadingDistpatcher;
    }),
  );

  @Effect()
  updateHeadquarterEmployee$ = this.actions$.pipe(
    ofType<HeadquarterEmployeeUpdated>(
      HeadquarterEmployeeActionTypes.HeadquarterEmployeeUpdated,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new HeadquarterEmployeesPageToggleLoading({ isLoading: true }),
      );
      return this.headquarterEmployeesService.updateHeadquarterSpec(
        payload.headquarterEmployee,
      );
    }),
    map(() => {
      return this.hideLoadingDistpatcher;
    }),
  );

  @Effect()
  createHeadquarterEmployee$ = this.actions$.pipe(
    ofType<HeadquarterEmployeeOnServerCreated>(
      HeadquarterEmployeeActionTypes.HeadquarterEmployeeOnServerCreated,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new HeadquarterEmployeesPageToggleLoading({ isLoading: true }),
      );
      return this.headquarterEmployeesService
        .createHeadquarterEmployee(
          payload.employeeId,
          payload.headquarterId,
          payload.role,
        )
        .pipe(
          tap((res) => {
            if (!res['employeeRequest']) {
              this.store.dispatch(
                new HeadquarterEmployeeCreated({ headquarterEmployee: res }),
              );
            } else {
              this.layoutUtilsService.showActionNotification(
                'A user with this role needs additional confirmation on the Employees requests tab',
                MessageType.Create,
              );
            }
          }),
        );
    }),
    map(() => {
      return this.hideLoadingDistpatcher;
    }),
  );

  constructor(
    private actions$: Actions,
    private layoutUtilsService: LayoutUtilsService,
    private headquarterEmployeesService: HeadquarterEmployeesService,
    private store: Store<AppState>,
  ) {}
}
