import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import {
  AuthNoticeService,
  AuthService,
  Register,
  User,
} from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';

@Component({
  selector: 'kt-register',
  templateUrl: './register.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;
  loading = false;
  errors: any = [];

  private unsubscribe: Subject<any>;

  constructor(
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private router: Router,
    private auth: AuthService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit() {
    this.initRegisterForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  initRegisterForm() {
    this.registerForm = this.fb.group(
      {
        fullname: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.minLength(3),
            Validators.maxLength(320),
          ]),
        ],
        username: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        confirmPassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        agree: [false, Validators.compose([Validators.required])],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      },
    );
  }

  submit() {
    const controls = this.registerForm.controls;
    if (this.registerForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );
      return;
    }

    this.loading = true;

    if (!controls['agree'].value) {
      this.authNoticeService.setNotice(
        'You must agree the terms and condition',
        'danger',
      );
      return;
    }

    const _user: User = new User();
    _user.clear();
    _user.email = controls['email'].value;
    _user.fullname = controls['fullname'].value;
    _user.password = controls['password'].value;
    _user.roles = [];
    this.auth
      .register(_user)
      .pipe(
        tap((user) => {
          if (user) {
            this.store.dispatch(new Register({ authToken: user.accessToken }));
            // pass notice message to the login page
            this.authNoticeService.setNotice(
              this.translate.instant('AUTH.REGISTER.SUCCESS'),
              'success',
            );
            this.router.navigateByUrl('/auth/login');
          } else {
            this.authNoticeService.setNotice(
              this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'),
              'danger',
            );
          }
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }

    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }
}
