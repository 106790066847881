import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OrderCommentService } from '../../../../core/order/_services/comment.service';
import { QueryParamsModel } from '../../../../core/_base/crud/models/query-models/query-params.model';
import { CommentModel } from '../../../../core/order/_models/comment.model';

@Component({
  selector: 'kt-order-comment',
  templateUrl: './order-comment.component.html',
  styleUrls: ['./order-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderCommentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() orderCancelReason: string;
  @Input() orderId: number;
  @ViewChild('sort1', {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  loading$: Observable<boolean>;
  commentToCancelOrderForm = new FormControl('', [Validators.required]);
  dataSource;
  displayedColumns: string[] = ['createdAt', 'text'];
  private destroyStream$ = new Subject<void>();
  private loadingSubject$ = new BehaviorSubject<boolean>(true);

  constructor(
    private translate: TranslateService,
    private commentService: OrderCommentService,
    private cdr: ChangeDetectorRef,
  ) {
    this.loading$ = this.loadingSubject$.asObservable(); // load display
  }

  ngOnInit() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroyStream$),
        tap(() => this.loadComents(this.orderId)),
      )
      .subscribe();

    if (this.orderId) {
      this.loadComents(this.orderId);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.commentToCancelOrderForm.patchValue(this.orderCancelReason);
  }

  ngOnDestroy() {
    this.loadingSubject$.next(false);
    this.destroyStream$.next();
  }

  public getComponentTitle() {
    return this.translate.instant('ORDER.TITLE_COMMENT');
  }

  private loadComents(id) {
    const queryParams = new QueryParamsModel(
      '',
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
    );

    this.commentService
      .findCommentsForOrder(id, queryParams)
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((comments: CommentModel[]) => {
        this.dataSource = new MatTableDataSource<CommentModel>(comments);
        this.cdr.detectChanges();
      });
  }

  public onReset() {
    this.commentToCancelOrderForm.reset();
    this.cdr.detectChanges();
  }

  public onSumbit() {
    if (!this.orderId) {
      return;
    }

    const newComent = { text: this.commentToCancelOrderForm.value };

    this.commentToCancelOrderForm.reset();

    this.commentService
      .addComment(this.orderId, newComent)
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((newComment: CommentModel) => {
        this.loadComents(this.orderId);
      });
  }
}
