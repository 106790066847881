<!--begin::Body-->
<div class="kt-login__body">
  <!--begin::Signin-->
  <div class="kt-login__form">
    <div class="kt-login__title">
      <h3>{{ 'AUTH.LOGIN.BUTTON' | translate }}</h3>
    </div>

    <!--begin::Form-->
    <form class="kt-form" [formGroup]="loginForm" autocomplete="off">
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
          <input
            matInput
            id="login-input"
            type="email"
            placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
            formControlName="email"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('email', 'required')">
            <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'email')">
            <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'minLength')">
            <strong
              >{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong
            >
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'maxLength')">
            <strong
              >{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong
            >
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
          <input
            matInput
            type="password"
            id="password-input"
            placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
            formControlName="password"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('password', 'required')">
            <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'minLength')">
            <strong
              >{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong
            >
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'maxLength')">
            <strong
              >{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong
            >
          </mat-error>
        </mat-form-field>
      </div>
      <a class="link" [routerLink]="['/auth/forgot-password']">{{
        'AUTH.GENERAL.FORGOT_BUTTON' | translate
      }}</a>
      <!--begin::Action-->
      <div class="kt-login__server-error">
        <p *ngFor="let error of errors">
          {{ 'AUTH.SERVER_ERRORS.' + error | translate }}
        </p>
      </div>
      <div class="kt-login__actions">
        <button
          (click)="submit()"
          [ngClass]="{
            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
              loading
          }"
          id="kt_login_signin_submit"
          class="btn btn-primary btn-elevate kt-login__btn-primary"
        >
          {{ 'AUTH.LOGIN.BUTTON' | translate }}
        </button>
      </div>
      <!--end::Action-->
    </form>
    <!--end::Form-->
  </div>
  <!--end::Signin-->
</div>
<!--end::Body-->
