// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
// CRUD
import {
  HttpUtilsService,
  QueryParamsModel,
  QueryResultsModel,
} from '../../_base/crud';
// Models
import { HeadquarterModel } from '../_models/headquarter.model';
import { environment } from '../../../../environments/environment';
import { PaginationResponse } from '../../_base/crud/models/pagination.response';

const API_HEADQUARTER_URL = `${environment.api_url}/headquarter`;
const API_STORE_URL = `${environment.api_url}/store`;

@Injectable()
export class HeadquartersService {
  lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(
    new QueryParamsModel({}, 'asc', '', 0, 10),
  );

  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {}

  createHeadquarter(headquarter): Observable<HeadquarterModel> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();

    let requestData = {
      name: headquarter.name,
      country: headquarter.country,
      googleTagManagerKey: headquarter.googleTagManagerKey,
      VAT: headquarter.VAT,
      staffBonus: headquarter.staffBonus,
      currency: headquarter.currency,
      bankIDVerification: headquarter.bankIDVerification,
      countryId: headquarter.countryId,
      isAnonymizationEnabled: Boolean(headquarter.enableAnonimization),
    };

    if (headquarter.enableAnonimization) {
      requestData['isAnonymizationEnabled'] = headquarter.enableAnonimization;
      requestData['anonymizeFinishedOrdersAfterDays'] = isNaN(
        Number(headquarter.finishedOrdersAnonimization),
      )
        ? 0
        : Number(headquarter.finishedOrdersAnonimization);
      requestData['anonymizeUnfinishedOrdersAfterDays'] = isNaN(
        Number(headquarter.unfinishedOrdersAnonimization),
      )
        ? 0
        : Number(headquarter.unfinishedOrdersAnonimization);
    }

    return this.http.post<HeadquarterModel>(API_HEADQUARTER_URL, requestData, {
      headers: httpHeaders,
    });
  }

  // READ
  getAllHeadquarters(): Observable<PaginationResponse<HeadquarterModel>> {
    return this.http.get<PaginationResponse<HeadquarterModel>>(
      API_HEADQUARTER_URL,
    );
  }

  getHeadquarterById(headquarterId: number): Observable<HeadquarterModel> {
    return this.http.get<HeadquarterModel>(
      API_HEADQUARTER_URL + `/${headquarterId}`,
    );
  }

  getEmployeeHeadquarter(): Observable<HeadquarterModel> {
    return this.http.get<HeadquarterModel>(API_HEADQUARTER_URL + `/management`);
  }

  findHeadquartersAutocomplete(
    query: string,
  ): Observable<[{ id: number; email: string }]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<[{ id: number; email: string }]>(
      `${environment.api_url}/headquarter/autocomplete?query=${query}`,
    );
    // return this.http.post<QueryResultsModel>(API_USERS_URL + '/findUsers', queryParams, { headers: httpHeaders });
  }

  removeManualPriceByProvider(providerId: number) {
    return this.http.delete(
      `${environment.api_url}/manual-price/provider/${providerId}`,
    );
  }

  findCountriesAutocomplete(
    query: string,
  ): Observable<[{ id: number; name: string }]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<[{ id: number; name: string }]>(
      `${environment.api_url}/headquarter/countries/autocomplete?query=${query}`,
    );
    // return this.http.post<QueryResultsModel>(API_USERS_URL + '/findUsers', queryParams, { headers: httpHeaders });
  }

  getProvidersList(headquarterId: number) {
    return this.http.get(
      `${environment.api_url}/headquarter/${headquarterId}/provider?page=1&limit=10`,
    );
  }

  modifyProviderCalcModel(
    headquarterId: number,
    providerId: number,
    calcModel: number,
    ask: boolean,
  ) {
    let requestData = {
      calculationModel: calcModel,
      askIsEmployedAtAuthorityPosition: ask,
    };

    return this.http.patch(
      `${environment.api_url}/headquarter/${headquarterId}/provider/${providerId}`,
      requestData,
    );
  }

  // Server should return filtered/sorted result
  findHeadquarters(
    queryParams: QueryParamsModel,
  ): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

    const url = API_HEADQUARTER_URL;
    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params: httpParams,
    });
  }

  // UPDATE => PUT: update the headquarter on the server
  updateHeadquarter(headquarter: HeadquarterModel): Observable<any> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.patch(
      `${API_HEADQUARTER_URL}/${headquarter.id}`,
      {
        name: headquarter.name,
        countryId: headquarter.countryId,
        googleTagManagerKey: headquarter.googleTagManagerKey,
        VAT: headquarter.VAT,
        staffBonus: headquarter.staffBonus,
        currency: headquarter.currency,
        bankIDVerification: headquarter.bankIDVerification,
        storeChangingAllowed: headquarter.storeChangingAllowed,
        onlineBankIDVerification: headquarter.onlineBankIDVerification,
        notAuthorCampaignApproval: headquarter.notAuthorCampaignApproval,
        campaignModule: headquarter.campaignModule,
        staffBonusModule: headquarter.staffBonusModule,
        focusContractMode: headquarter.focusContractMode,
        hideCustomerInfo: headquarter.hideCustomerInfo,
        enableSalesDiscount: headquarter.enableSalesDiscount,
      },
      { headers: httpHeaders },
    );
  }


  updateStatusForHeadquarter(
    headquarters: HeadquarterModel[],
    status: number,
  ): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      headquartersForUpdate: headquarters,
      newStatus: status,
    };
    const url = API_HEADQUARTER_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the headquarter from the server
  deleteHeadquarter(headquarterId: number): Observable<HeadquarterModel> {
    const url = `${API_HEADQUARTER_URL}/${headquarterId}`;
    return this.http.delete<HeadquarterModel>(url);
  }

  deleteHeadquarters(ids: number[] = []): Observable<any> {
    const url = API_HEADQUARTER_URL + '/delete';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { prdocutIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, {
      headers: httpHeaders,
    });
  }

  getFiltersForContract(headquarterId: number) {
      return this.http.get(
          `${API_HEADQUARTER_URL}/${headquarterId}/catalog/filters`,
      );
  }
}
