<!--begin::Body-->
<div class="kt-login__body">
  <!--begin::Signin-->
  <div class="kt-login__form">
    <div class="kt-login__title">
      <h3>{{ 'AUTH.FORGOT.TITLE' | translate }}</h3>
    </div>

    <kt-auth-notice></kt-auth-notice>

    <form class="kt-form" [formGroup]="forgotPasswordForm" autocomplete="off">
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
          <input
            matInput
            type="email"
            placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
            formControlName="email"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('email', 'required')">
            <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'email')">
            <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'minLength')">
            <strong
              >{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong
            >
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'maxLength')">
            <strong
              >{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong
            >
          </mat-error>
        </mat-form-field>
      </div>
      <!--begin::Action-->
      <div class="kt-login__actions">
        <button
          type="button"
          routerLink="/auth/login"
          [disabled]="loading"
          id="kt_login_signup_cancel"
          class="btn btn-secondary btn-elevate kt-login__btn-secondary"
        >
          {{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}
        </button>
        <button
          type="submit"
          (click)="submit()"
          id="kt_login_signin_submit"
          class="btn btn-primary btn-elevate kt-login__btn-primary"
          [ngClass]="{
            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
              loading
          }"
        >
          {{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}
        </button>
      </div>
      <!--end::Action-->
    </form>
  </div>
</div>
