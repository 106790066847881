import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, mergeMap, tap } from 'rxjs/operators';
import {
  LayoutUtilsService,
  MessageType,
  QueryResultsModel,
} from '../../_base/crud';
import { EmployeesRequestsService } from '../_services/';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  EmployeesStoreRequestsActionTypes,
  EmployeesStoreRequestsPageLoaded,
  EmployeesStoreRequestsPageRequested,
  EmployeesStoreRequestsPageToggleLoading,
  EmployeesStoreRequestsUpdated,
  OneEmployeesStoreRequestsDeleted,
} from '../_actions/store-employee-requests';

@Injectable({
  providedIn: 'root',
})
export class EmployeesStoreRequestsEffects {
  hideLoadingDistpatcher = new EmployeesStoreRequestsPageToggleLoading({
    isLoading: false,
  });

  @Effect()
  loadStoreEmployeesPage$ = this.actions$.pipe(
    ofType<EmployeesStoreRequestsPageRequested>(
      EmployeesStoreRequestsActionTypes.EmployeesStoreRequestsPageRequested,
    ),
    mergeMap(({ payload }) =>
      this.employeesRequestsService.findStoreEmployees(
        payload.page,
        payload.storeId,
      ),
    ),
    map((result: QueryResultsModel) => {
      return new EmployeesStoreRequestsPageLoaded({
        employeesRequests: result.items,
        totalCount: result.totalCount,
      });
    }),
  );

  @Effect()
  updateStoreEmployee$ = this.actions$.pipe(
    ofType<EmployeesStoreRequestsUpdated>(
      EmployeesStoreRequestsActionTypes.EmployeesStoreRequestsUpdated,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new EmployeesStoreRequestsPageToggleLoading({ isLoading: true }),
      );
      return this.employeesRequestsService
        .updateEmployeesStoreStatus(payload.employeeRequests)
        .pipe(
          tap(() => {
            this.store.dispatch(
              new OneEmployeesStoreRequestsDeleted(payload.employeeRequests.id),
            );
            this.layoutUtilsService.showActionNotification(
              'The user was successfully transferred to the selected status',
              MessageType.Create,
            );
          }),
        );
    }),
    map((r) => {
      return this.hideLoadingDistpatcher;
    }),
  );

  constructor(
    private actions$: Actions,
    private layoutUtilsService: LayoutUtilsService,
    private employeesRequestsService: EmployeesRequestsService,
    private store: Store<AppState>,
  ) {}
}
