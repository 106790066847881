import { BaseModel } from '../../_base/crud';

export class HeadquarterEmployeeModel extends BaseModel {
  id: number;
  headquarterId: number;
  employeeId: number;
  email: string;

  clear(headquarterId: number) {
    this.id = undefined;
    this.headquarterId = headquarterId;
    this.email = '';
    this.employeeId = undefined;
  }
}
