// Angular
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeadquartersService } from '../../../../../../../../core/headquarter';
import { FormValidationRegExService } from '../../../../../../../../core/_base/layout';
import {HeadquarterConfiguration} from '../../../../../../../../core/catalog/_services/provider.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'kt-store-edit-dialog',
  templateUrl: './store-edit-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreEditDialogComponent implements OnInit {
  storeEditForm: FormGroup;
  viewLoading: boolean = true;
  loadingAfterSubmit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<StoreEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private headquarterService: HeadquartersService,
    private validate: FormValidationRegExService,
  ) {}

  ngOnInit() {
    this.initSpecificationForm();
  }

  initSpecificationForm() {
    console.log(this.data.headquarterConfigurationCode);
    let inputs = {
      name: ['', [this.validate.name()]],
      address: ['', [this.validate.text()]],
      phone: ['', [this.validate.phone()]],
      externalReferenceId: ['', [this.validate.text()]],
      headquarterId: [this.data.headquarterId, [Validators.required]],
    };

    this.storeEditForm = this.fb.group(inputs);

    this.viewLoading = false; // Remove this line
    this.cdr.detectChanges(); // Remove this line
  }

  onNoClick(): void {
    this.dialogRef.close({ isUpdated: false });
  }

  save() {
    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    this.viewLoading = false;
    this.closeDialog(this.storeEditForm.value);
  }

  closeDialog(store) {
    this.dialogRef.close(store);
  }
}
