<!--begin::Body-->
<div class="kt-login__body">
  <!--begin::Signin-->
  <div class="kt-login__form">
    <div class="kt-login__title">
      <h3>Enter your new password</h3>
    </div>

    <kt-auth-notice></kt-auth-notice>

    <form class="kt-form" [formGroup]="resetPasswordForm" autocomplete="off">
      <ng-container *ngIf="showPasswordForm">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
            <input
              matInput
              type="password"
              placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
              formControlName="password"
              autocomplete="off"
            />
            <mat-error *ngIf="isControlHasError('password', 'required')">
            <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
            </mat-error>
            <!-- <mat-error *ngIf="isControlHasError('password','password')">
                  <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
                </mat-error> -->
            <mat-error *ngIf="isControlHasError('password', 'minLength')">
              <strong
                >{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong
              >
            </mat-error>
            <mat-error *ngIf="isControlHasError('password', 'maxLength')">
              <strong
              >{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong
              >
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-label>Repeat password</mat-label>
            <input
              matInput
              type="password"
              placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
              formControlName="confirmPassword"
              autocomplete="off"
            />
            <mat-error *ngIf="isControlHasError('password', 'required')">
            <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
            </mat-error>
            <mat-error
              *ngIf="
                resetPasswordForm.get('confirmPassword').errors &&
                resetPasswordForm.get('confirmPassword').errors.ConfirmPassword
              "
            >
              <strong>Passsword and ConfirmPassword didn't match.</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>

      <!--begin::Action-->
      <div class="kt-login__actions">
        <button
          type="button"
          routerLink="/auth/login"
          [disabled]="loading"
          id="kt_login_signup_cancel"
          class="btn btn-secondary btn-elevate kt-login__btn-secondary"
        >
          {{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}
        </button>
        <button
          type="submit"
          *ngIf="!showLoginButton"
          (click)="submit()"
          id="kt_login_signin_submit"
          class="btn btn-primary btn-elevate kt-login__btn-primary"
          [ngClass]="{
            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
              loading
          }"
        >
          {{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}
        </button>
        <button
          type="submit"
          *ngIf="showLoginButton"
          (click)="goToLogin()"
          id="kt_login_signin_submit"
          class="btn btn-primary btn-elevate kt-login__btn-primary"
        >
          To login
        </button>
      </div>
      <div class="kt-login__actions">
        <div></div>
        <div *ngIf="errorMessage !== ''">{{ errorMessage }}</div>
      </div>
      <!--end::Action-->
    </form>
  </div>
</div>
