// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import {
  filter,
  mergeMap,
  tap,
  withLatestFrom,
  catchError,
} from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import {
  AuthActionTypes,
  Login,
  Logout,
  Register,
  UserLoaded,
  UserRequested,
} from '../_actions/auth.actions';
import { AuthService } from '../_services/index';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded } from '../_selectors/auth.selectors';
import {LocalStorageService} from "../../services/local-storage.service";

@Injectable()
export class AuthEffects {
  @Effect({ dispatch: false })
  login$ = this.actions$.pipe(
    ofType<Login>(AuthActionTypes.Login),
    tap((action) => {
        LocalStorageService.save(environment.authTokenKey, action.payload.authToken);
        LocalStorageService.save('refresh', action.payload.refreshToken);
      this.store.dispatch(new UserRequested());
    }),
    catchError((err) => of({})),
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    tap(() => {
        LocalStorageService.remove(environment.authTokenKey);
        LocalStorageService.remove('refresh');
      this.router.navigate(['/auth/login'], {
        queryParams: { returnUrl: this.returnUrl },
      });
    }),
  );

  @Effect({ dispatch: false })
  register$ = this.actions$.pipe(
    ofType<Register>(AuthActionTypes.Register),
    tap((action) => {
        LocalStorageService.save(environment.authTokenKey, action.payload.authToken);
    }),
  );

  @Effect({ dispatch: false })
  loadUser$ = this.actions$.pipe(
    ofType<UserRequested>(AuthActionTypes.UserRequested),
    withLatestFrom(this.store.pipe(select(isUserLoaded))),
    filter(([action, _isUserLoaded]) => !_isUserLoaded),
    mergeMap(([action, _isUserLoaded]) => {
      return this.auth.getUserByToken().pipe(
        catchError((err) => {
          return of(err);
        }),
      );
    }
    ),
    tap((_user) => {
      if (_user) {
        if (_user.headquarterId != null && !LocalStorageService.get('headquarter')) {
          LocalStorageService.save('headquarter', _user.headquarter);
        }
        this.store.dispatch(new UserLoaded({ user: _user }));
      } else {
        this.store.dispatch(new Logout());
      }
    }),
    catchError((err) => {
      return of({});
    }),
  );

  @Effect()
  init$: Observable<Action> = defer(() => {
    const userToken = LocalStorageService.get(environment.authTokenKey);
    const refreshToken = LocalStorageService.get('refresh');
    let observableResult = of({ type: 'NO_ACTION' });
    if (userToken) {
      observableResult = of(
        new Login({
          authToken: userToken,
          refreshToken: refreshToken,
        }),
      );
    }
    return observableResult;
  });

  private returnUrl: string;

  constructor(
    private actions$: Actions,
    private router: Router,
    private auth: AuthService,
    private store: Store<AppState>,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // this.returnUrl = event.url;
        this.returnUrl = '/catalog/list';
      }
    });
  }
}
