import { BaseModel } from '../../_base/crud';
import { HeadquarterModel } from '../../headquarter';

export class StoreModel {
  id: number;
  name: string;
  address: string;
  phone: string;
  autologinIp: number;
  autologinUserId: number;
  externalReferenceId: string;
  operatorStoreId?: string;
  insuranceStoreId?: string;
  ticketingStoreId?: string;
  headquarter: HeadquarterModel;
  headquarterId: number;
  ipRanges: Array<any>;
  outletId: string;
  tele2RefId: string;
  teliaRefId: string;

  clear() {
    this.name = '';
    this.address = '';
    this.phone = '';
    this.externalReferenceId = '';
    this.operatorStoreId = '';
    this.insuranceStoreId = '';
    this.ticketingStoreId = '';
    this.headquarter = null;
    this.headquarterId = null;
    this.autologinIp = null;
    this.autologinUserId = null;
    this.ipRanges = null;
    this.outletId = null;
    this.tele2RefId = '';
    this.teliaRefId = '';
  }
}
