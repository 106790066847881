/**
 * Контракт с выбранным Провайдером(ProviderModel) и Продуктом(ProductModel)
 */

import { HeadquarterProviderModel } from './headquarterProviderModel';

export interface ScreenProtector {
  articleNumber?: number;
  articleName?: string;
  productType: string;
  salesPrice?: number;
  monthlyCost?: number;
}

export interface Insurance {
  id: number;
  articleNumber?: number;
  articleName?: string;
  ean?: string;
  salesPrice?: number;
  storeId?: number;
  isPublicVisible?: boolean;
  minSalesPrice?: number;
  maxSalesPrice?: number;
  screenProtector?: boolean;
  selected?: boolean;
  monthlyCost?: number;
}

export enum ContractType {
  new = 'new',
  extension = 'extension',
}

export enum ContractGroup {
  New = 1,
  Extension = 2,
  Subvention = 3,
}

export enum TypeCalculationUsageData {
  calculator = 'Calculator',
  simple = 'Simple',
}

export enum ContractLength {
  halfYear = 6,
  oneYear = 12,
  twoYears = 24,
  // threeYears = '36',
}

export class ContractModel {
  id?: number;
  headquarterProviderId: number = 0;
  providersId: number = 0; //todo rename later after removing old provider
  headquarterProvider: HeadquarterProviderModel = new HeadquarterProviderModel();
  articleNumber: string = '';
  articleName: string = '';
  totalCommission: number = 0; // комисия
  marginPercent: number = 0;
  amountKept: number = 0;
  active: boolean = false; // выбранный контракт
  contractType: string = '';
  productGroup: ContractType = ContractType.new; // тип контракта + в фильтр для получения списка контрактов
  contractLength: ContractLength = ContractLength.twoYears; // длительность контракта. +  в фильтр для получения списка контрактов
  calculationType: TypeCalculationUsageData =
    TypeCalculationUsageData.calculator;
  calculationModel: number = 0;
  monthlyCost: number = 0;
  monthlyDiscount: number = 0;

  discountLength: number = 0;
  dataIncluded: number = 0;
  dataIncludedMin: number = 0;
  dataIncludedMax: number = 100;
  startingFee: number = 0;
  startingFeeInstore: boolean = false;
  subvention?: any = null;
  bonus: number = 0;
  discount: number = 0;
  ignoreStartingFee: boolean = false;
  manualPrice: {
    id: number;
    deviceId: number;
    contractId: number;
    monthlyCost: number;
    netPrice: number;
    toPay: number;
  };
  extraSubventionProduct: {
    articleDescription: string;
    articleNumber: number;
  };
  extraSubventionProducts: Array<{
    articleDescription: string;
    articleNumber: string;
  }>;
  deviceMonthlyCost?: number;
  deviceContractSettings?: {
    id?: number;
    deviceId?: number;
    contractId?: number;
    campaign?: boolean;
    priority?: number;
  };
  providerAPI?: boolean;
  product?: {
    currentsp: number;
    promoCode: number;
  };
  providerContractRef?: any;
  providerContract?: any;
  specialOffers?: Array<ISpecialOffer>;
  specialOffer?: ISpecialOffer;
  offerId?: any;
  monthly: Array<IMonthlyCost>;
  offerIds?: string[];

  constructor(init?: Partial<ContractModel>) {
    Object.assign(this, init);
  }
}

export class CountableContractModel extends ContractModel {
    count?: number;
}

export interface ISpecialOffer {
  name: string;
  cost: Array<{
    price: number;
    month: string;
  }>;
  commitment: number;
  offerData: any;
  selectionType: 'single' | 'multiple';
}

interface IMonthlyCost {
    price: number;
    from: number;
    to: number;
}
