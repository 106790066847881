// import { BaseModel } from '../../_base/crud';
import { HeadquarterEmployeeModel } from './headquarter-employee.model';

export class HeadquarterModel {
  id: number;
  name: string;
  country: {
    id: number;
    name: string;
  };
  googleTagManagerKey: string;
  VAT: number;
  staffBonus: number;
  bankIDVerification: boolean;
  onlineBankIDVerification: boolean;
  notAuthorCampaignApproval: boolean;
  storeChangingAllowed: boolean;
  currency: string;
  countryId: number;
  isAnonymizationEnabled: boolean;
  anonymizeFinishedOrdersAfterDays: number;
  anonymizeUnfinishedOrdersAfterDays: number;
  campaignModule: boolean;
  staffBonusModule: boolean;
  focusContractMode: boolean;
  hideCustomerInfo: boolean;
  enableSalesDiscount: boolean;

  configuration: {
    code: string;
  };

  _employees: HeadquarterEmployeeModel[];

  constructor(init?) {
    Object.assign(this, init);
  }

  clear() {
    this.name = '';
    this.country = null;
    this.googleTagManagerKey = '';
    this.VAT = 0;
    this.staffBonus = 0;
    this.bankIDVerification = false;
    this.storeChangingAllowed = false;
    this.onlineBankIDVerification = false;
    this.currency = null;
    this.countryId = null;
  }
}
