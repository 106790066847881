import { DeviceModel } from './device.model';
import { ContractModel, Insurance, ScreenProtector } from './contract.model';
import { DiscountArticlesModel } from './discount-articles.model';
import { CalculateAccessory } from './accessory.model';
import { CalculateAdditionalDevice } from './calculate-additional-device.model';

export interface TemporaryOrder {
  deviceId?: number;
  contractId?: number;
  offerId?: any;
  insuranceId?: number;
  uuid?: string;
}

/**
 * Ответ от бека с пересчитанными полями для "Card"
 */
export class OrderCheckoutModel {
  accessories?: CalculateAccessory[];
  additionalDevices?: CalculateAdditionalDevice[];
  contract: ContractModel = new ContractModel(); // выбранное
  device: DeviceModel = new DeviceModel(); // выбранное
  subvention: {
    amountExclVAT: number;
    articleName: string;
    articleNumber: string;
    id: number;
    monthlyCost: number;
    months: number;
    providerId: number;
  }; // расчеты от бека, для ордера.
  subventions: { monthlyCost: number; amountExcludeVAT: number }[]; // доступные растрочки для слайдера
  extraSubvention: any; // ?
  extraSubventions: Array<any>;
  discount: number;
  toPay: number; // device
  total: number; // device + contract
    extras?: Array<any>;
    oneTimeFee: number;
  additionalServices: [];
  insurances?: Insurance[];
  insuranceId?: number;
  insurance?: Insurance;
  screenProtector?: ScreenProtector;
  minimumTotalCost: null;
  selectedAdditionalServices: [];
  products?: [];
  salesDiscount: number;
  customSalesDiscount: {value: string; type: string; cost?: string};
  startingFee: {
    articleName: string;
    articleNumber: number;
    startingFee: number;
  };
  agreementDoc: any;
  additionalArticle: {
    articleDescription: string;
    articleNumber: number;
  };
  maximumToPay?: number;
  temporaryOrder?: TemporaryOrder;
  financingAmount?: number;
  afterFinancingMonthlyPayment?: number;
  withFinancingMonthlyPayment?: number;
  financingAmountMonthly?: number;
  availableDiscountsArticles: DiscountArticlesModel[];
  discountArticles?: { id: number }[];
  maxSalesDiscount?: number;
  salesDiscountArticles?: any[];
  noFinancing?: boolean;
  totalSum: number;
  providerData?: any;
  installmentFee?: number;
  powerDiscount?: {key: string, value: string}[];
  margin?: number;
  canApplyAnotherDiscount?: boolean;
  campaignBundlings?: {articleNumber: number, articleName: string}[]

  constructor(init?: Partial<OrderCheckoutModel>) {
    Object.assign(this, init);
  }
}
