// Angular
import { Injectable } from '@angular/core';
// RxJS
import { of } from 'rxjs';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { StoreEmployeesService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
  StoreEmployeeActionTypes,
  StoreEmployeesPageRequested,
  StoreEmployeesPageLoaded,
  ManyStoreEmployeesDeleted,
  OneStoreEmployeeDeleted,
  StoreEmployeesPageToggleLoading,
  StoreEmployeeUpdated,
  StoreEmployeeCreated,
  StoreEmployeeOnServerCreated,
} from '../_actions/store-employee.actions';

@Injectable()
export class StoreEmployeeEffects {
  // showLoadingDistpatcher = new ProcutEmployeesPageToggleLoading({ isLoading: true });
  hideLoadingDistpatcher = new StoreEmployeesPageToggleLoading({
    isLoading: false,
  });

  @Effect()
  loadStoreEmployeesPage$ = this.actions$.pipe(
    ofType<StoreEmployeesPageRequested>(
      StoreEmployeeActionTypes.StoreEmployeesPageRequested,
    ),
    mergeMap(({ payload }) =>
      this.storeEmployeesService.findStoreEmployees(
        payload.page,
        payload.storeId,
      ),
    ),
    map((result: QueryResultsModel) => {
      return new StoreEmployeesPageLoaded({
        storeEmployees: result.items,
        totalCount: result.totalCount,
      });
    }),
  );

  @Effect()
  deleteStoreEmployee$ = this.actions$.pipe(
    ofType<OneStoreEmployeeDeleted>(
      StoreEmployeeActionTypes.OneStoreEmployeeDeleted,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new StoreEmployeesPageToggleLoading({ isLoading: true }),
      );
      return this.storeEmployeesService.deleteStoreEmployee(
        payload.storeId,
        payload.employeeId,
      );
    }),
    map(() => {
      return this.hideLoadingDistpatcher;
    }),
  );

  @Effect()
  deleteStoreEmployees$ = this.actions$.pipe(
    ofType<ManyStoreEmployeesDeleted>(
      StoreEmployeeActionTypes.ManyStoreEmployeesDeleted,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new StoreEmployeesPageToggleLoading({ isLoading: true }),
      );
      return this.storeEmployeesService.deleteStoreEmployees(payload.ids);
    }),
    map(() => {
      return this.hideLoadingDistpatcher;
    }),
  );

  @Effect()
  updateStoreEmployee$ = this.actions$.pipe(
    ofType<StoreEmployeeUpdated>(StoreEmployeeActionTypes.StoreEmployeeUpdated),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new StoreEmployeesPageToggleLoading({ isLoading: true }),
      );
      return this.storeEmployeesService.updateStoreSpec(payload.storeEmployee);
    }),
    map(() => {
      return this.hideLoadingDistpatcher;
    }),
  );

  @Effect()
  createStoreEmployee$ = this.actions$.pipe(
    ofType<StoreEmployeeOnServerCreated>(
      StoreEmployeeActionTypes.StoreEmployeeOnServerCreated,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new StoreEmployeesPageToggleLoading({ isLoading: true }),
      );
      return this.storeEmployeesService
        .createStoreEmployee(payload.employeeId, payload.storeId, payload.role)
        .pipe(
          tap((res) => {
            if (!res['employeeRequest']) {
              this.store.dispatch(
                new StoreEmployeeCreated({ storeEmployee: res }),
              );
            }
          }),
        );
    }),
    map(() => {
      return this.hideLoadingDistpatcher;
    }),
  );

  constructor(
    private actions$: Actions,
    private storeEmployeesService: StoreEmployeesService,
    private store: Store<AppState>,
  ) {}
}
